import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { isGraphLow, format2decimal } from "../../../constants/utils";
import { lRed, dRed, lGreen, dGreen } from "../../../constants/constants";

const RatioAnalysis = ({
  data,

  title,
  totalYears,
}) => {
  const generateChartOptions = (data, totalYears) => {
    // Extract data for the specific field
    const companyData = data.slice(0, totalYears).map((item) => item[1]);

    // Extract years from company data (assuming years are the same across company, sector, and industry)
    const years = data.slice(0, totalYears).map((item) => item[0]);

    return {
      chart: {
        type: "spline",
        height: "300px",
      },
      title: {
        text: `${title} Comparison`,
      },
      xAxis: {
        title: {
          text: "Year",
        },
        categories: years, // Use years as x-axis labels
      },
      yAxis: {
        title: {
          text: `Value`,
        },
      },
      tooltip: {
        shared: true,
        valueDecimals: 2,
      },
      series: [
        {
          name: `Company`,
          data: companyData,
        },
      ],
    };
  };

  const redPlotOptions = {
    spline: {
      animation: {
        duration: 5000,
      },
      marker: {
        fillColor: dRed,
      },
      lineColor: dRed,
      lineWidth: 2,
      states: {
        hover: {
          lineWidth: 3,
        },
      },
    },
  };
  const greenPlotOptions = {
    spline: {
      animation: {
        duration: 5000,
      },
      marker: {
        fillColor: dGreen,
      },
      lineColor: dGreen,
      lineWidth: 2,
      states: {
        hover: {
          lineWidth: 3,
        },
      },
    },
  };

  return (
    <div>
      {/* {Object.keys(fields).map((field, index) => ( */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "50px",
          marginRight: "100px",
          marginBottom: "50px",
        }}
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            ...generateChartOptions(data, totalYears),

            plotOptions: isGraphLow(data) ? redPlotOptions : greenPlotOptions,
          }}
        />
      </div>
      {/* ))} */}
    </div>
  );
};

export default RatioAnalysis;
