import ReactModal from "react-modal";
import "./PortfolioModal.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
const PortfolioModel = ({
  open,
  title,
  handleClose,
  UpcomingEarningData,
  UpcomingSplitsData,
  UpcomingNewsData,
  ReportData,
}) => {
  const [symbol, type] = title.split(" | ");

  return (
    <ReactModal
      style={{
        content: {
          position: "absolute",
          top: "55%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          border: "1px solid #ccc",
          background: "#fff",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "4px",
          outline: "none",
          padding: "20px",
          width: "1400px",

          height: "600px",
          overflow: "scroll",
        },
      }}
      isOpen={open}
    >
      <div className="modal-header">
        <div className="title">{title}</div>
        <button onClick={handleClose}>X</button>
      </div>
      <div className="modal-body">
        {type?.trim() == "Upcoming Earnings" && UpcomingEarningData && (
          <UpcomingEarningsTable
            earnings={UpcomingEarningData.response.earningsResponse.earnings}
          />
        )}

        {type?.trim() == "Upcomings Splits" && UpcomingSplitsData && (
          <UpcomingSplits
            splits={UpcomingSplitsData.response.splitsResponse.splits}
          />
        )}
        {type?.trim() == "Upcomings News" && UpcomingNewsData && (
          <UpcomingNews news={UpcomingNewsData.response.newsRes} />
        )}

        {(type?.trim() == "Annual Report" ||
          type.trim() == "Quarterly Report") &&
          ReportData && (
            <ReportView report={ReportData?.response} type={type.trim()} />
          )}
      </div>
    </ReactModal>
  );
};

export default PortfolioModel;

const ReportView = ({ report, type }) => {
  let objKey = type == "Annual Report" ? "yearly" : "quarterly";
  return (
    <div>
      <h4 color="black" style={{ marginBottom: "10px" }}>
        {type} Data
      </h4>
      <Tabs>
        <TabList>
          <Tab>Balance Sheet</Tab>
          <Tab>Cash Flow</Tab>
          <Tab>Income Statement</Tab>
        </TabList>

        <TabPanel>
          <ReportTable data={report?.Balance_Sheet?.[objKey]} />
        </TabPanel>
        <TabPanel>
          <ReportTable data={report?.Cash_Flow?.[objKey]} />
        </TabPanel>
        <TabPanel>
          <ReportTable data={report?.Income_Statement?.[objKey]} />
        </TabPanel>
      </Tabs>
    </div>
  );
};

const ReportTable = ({ data }) => {
  let firstObj = Object.values(data)[0];

  return (
    <table border="1">
      <thead>
        <tr>
          {/* Display the headers dynamically */}
          {firstObj &&
            Object.keys(firstObj).map((key) => <th key={key}>{key}</th>)}
        </tr>
      </thead>
      <tbody>
        {/* Iterate through each object and display its values */}
        {data &&
          Object.keys(data).map((key, index) => (
            <tr key={index}>
              {Object.values(data[key]).map((value, i) => (
                <td key={i}>{value === null ? "N/A" : value}</td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  );
};
// const ReportTable = ({ data }) => {
//   // Step 1: Get the first object to generate headers
//   let firstObj = Object.values(data)[0];

//   // Step 2: Identify columns where all values are NOT null
//   let validColumns = [];

//   if (data && firstObj) {
//     validColumns = Object.keys(firstObj).filter((key) => {
//       return Object.values(data).every((row) => row[key] !== null);
//     });
//   }

//   return (
//     <table border="1">
//       <thead>
//         <tr>
//           {/* Display only valid headers */}
//           {validColumns.map((key) => (
//             <th key={key}>{key}</th>
//           ))}
//         </tr>
//       </thead>
//       <tbody>
//         {/* Iterate through each object and display only valid column values */}
//         {data &&
//           Object.keys(data).map((key, index) => (
//             <tr key={index}>
//               {validColumns.map((column, i) => (
//                 <td key={i}>{data[key][column] === null ? "N/A" : data[key][column]}</td>
//               ))}
//             </tr>
//           ))}
//       </tbody>
//     </table>
//   );
// };

const UpcomingNews = ({ news }) => {
  return (
    <div>
      <h4 color="black" style={{ marginBottom: "10px" }}>
        News Data
      </h4>

      {!news?.length ? (
        <>
          <h5>No Upcoming News Found!</h5>
        </>
      ) : (
        <table border="1">
          <thead>
            <tr>
              <th>Date</th>
              <th>Title</th>
              <th>Content</th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody>
            {news.map((item, index) => (
              <tr key={index}>
                <td>{item.date.toLocaleString()}</td>
                <td>{item.title}</td>
                <td>{item.content.slice(0, 100)}</td>
                <td>
                  <a href={item.link}>{item.link}</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

const UpcomingSplits = ({ splits }) => {
  return (
    <div>
      <h4 color="black" style={{ marginBottom: "10px" }}>
        Split Table
      </h4>

      {!splits.length ? (
        <>
          <h5>No Upcoming Splits Data Found!</h5>
        </>
      ) : (
        <table border="1">
          <thead>
            <tr>
              <th>Company Code</th>
              <th>Report Date</th>
              <th>Date</th>
              <th>Before/After Market</th>
              <th>Currency</th>
              <th>Actual</th>
              <th>Estimate</th>
              <th>Difference</th>
              <th>Percent</th>
            </tr>
          </thead>
          <tbody>
            {splits.map((split, index) => (
              <tr key={index}>
                <td>{split.code}</td>
                <td>{split.report_date}</td>
                <td>{split.date}</td>
                <td>{split.before_after_market}</td>
                <td>{split.currency}</td>
                <td>{split.actual}</td>
                <td>{split.estimate}</td>
                <td>{split.difference}</td>
                <td>{split.percent.toFixed(2)}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

const UpcomingEarningsTable = ({ earnings }) => {
  return (
    <div>
      <h4 color="black" style={{ marginBottom: "10px" }}>
        Earnings Table
      </h4>
      <table border="1">
        <thead>
          <tr>
            <th>Company Code</th>
            <th>Report Date</th>
            <th>Date</th>
            <th>Before/After Market</th>
            <th>Currency</th>
            <th>Actual</th>
            <th>Estimate</th>
            <th>Difference</th>
            <th>Percent</th>
          </tr>
        </thead>
        <tbody>
          {earnings.map((earning, index) => (
            <tr key={index}>
              <td>{earning.code}</td>
              <td>{earning.report_date}</td>
              <td>{earning.date}</td>
              <td>{earning.before_after_market}</td>
              <td>{earning.currency}</td>
              <td>{earning.actual}</td>
              <td>{earning.estimate}</td>
              <td>{earning.difference}</td>
              <td>{earning.percent.toFixed(2)}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
