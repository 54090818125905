import {
  faArrowUpRightDots,
  faBullseye,
  faCircleInfo,
  faScaleUnbalancedFlip,
  faUpRightAndDownLeftFromCenter,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import RatioSplineChart from "./RatioSplineChart";

const RatioAnalysis = ({ data, totalYears }) => {
  const [ratioTab, setRatioTab] = useState("valuataion");

  return (
    <section style={{ position: "relative" }} id="ratio-analysis">
      <div className="qi-tooltip">
        <FontAwesomeIcon icon={faCircleInfo} />
        <div className="tooltiptext">
          Ratio Analysis - Examining four years' worth of calculated ratios can
          provide valuable insights into the performance of a company's four
          fundamental pillars, indicating whether they are experiencing growth
          or decline. ratios analysis advisor Analyzing four years of calculated
          ratios can offer valuable insights into how a company's four
          fundamental pillars compare to those of its industry and sector.
        </div>
      </div>
      {/* <h2>Ratio Analysis</h2> */}
      {/* Add the chart content here */}
      <div className="horiz-buttons ra-header-btns">
        <div>
          <button
            className={ratioTab === "valuataion" ? "active" : ""}
            onClick={() => setRatioTab("valuataion")}
          >
            Valuations <FontAwesomeIcon icon={faScaleUnbalancedFlip} />
          </button>
        </div>
        <div>
          <button
            className={ratioTab === "risk" ? "active" : ""}
            onClick={() => setRatioTab("risk")}
          >
            Risk <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
          </button>
        </div>
        <div>
          <button
            className={ratioTab === "effeciency" ? "active" : ""}
            onClick={() => setRatioTab("effeciency")}
          >
            Effeciency <FontAwesomeIcon icon={faBullseye} />
          </button>
        </div>
        <div>
          <button
            className={ratioTab === "profitability" ? "active" : ""}
            onClick={() => setRatioTab("profitability")}
          >
            Profitability <FontAwesomeIcon icon={faArrowUpRightDots} />
          </button>
        </div>
      </div>
      <div className="ratio-charts-block">
        {ratioTab === "valuataion" && (
          <div
            className="ratio-valuations-charts"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {data?.benGraham && (
              <RatioSplineChart
                totalYears={totalYears}
                data={data.benGraham}
                title="Ben Graham Number"
              />
            )}
            {data?.enterpriseValue && (
              <RatioSplineChart
                totalYears={totalYears}
                data={data.enterpriseValue}
                title="Enterprise Value (EV)"
              />
            )}
            {data?.peRatio && (
              <RatioSplineChart
                totalYears={totalYears}
                data={data.peRatio}
                title="P/E Ratio (Adjusted)"
              />
            )}
            {data?.pbRatio && (
              <RatioSplineChart
                totalYears={totalYears}
                data={data.pbRatio}
                title="P/B Ratio"
              />
            )}
            {data?.priceSales && (
              <RatioSplineChart
                totalYears={totalYears}
                data={data.priceSales}
                title="price/ sales"
              />
            )}
            {data?.bookValue && (
              <RatioSplineChart
                totalYears={totalYears}
                data={data.bookValue}
                title="book value per share"
              />
            )}
          </div>
        )}
        {ratioTab === "risk" && (
          <div
            className="ratio-risk-charts hidden"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {data?.currentRatio && (
              <RatioSplineChart
                totalYears={totalYears}
                data={data.currentRatio}
                title="current Ratio"
              />
            )}
            {data?.debtEquity && (
              <RatioSplineChart
                totalYears={totalYears}
                data={data.debtEquity}
                title="Debt/Equity"
              />
            )}
            {data?.equityAsset && (
              <RatioSplineChart
                totalYears={totalYears}
                data={data.equityAsset}
                title="Equity/Assets"
              />
            )}
          </div>
        )}
        {ratioTab === "effeciency" && (
          <div
            className="ratio-effeciency-charts hidden"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {data?.assetTurnover && (
              <RatioSplineChart
                totalYears={totalYears}
                data={data.assetTurnover}
                title="Assest turnover"
              />
            )}
            {data?.inventoryTurnover && (
              <RatioSplineChart
                totalYears={totalYears}
                data={data.inventoryTurnover}
                title="Inventory Turnover"
              />
            )}
            {data?.workingCapital && (
              <RatioSplineChart
                totalYears={totalYears}
                data={data.workingCapital}
                title="working capital"
              />
            )}
            {data?.returnOnAssets && (
              <RatioSplineChart
                totalYears={totalYears}
                data={data.returnOnAssets}
                title="return on assests"
              />
            )}
            {data?.returnOnEquity && (
              <RatioSplineChart
                totalYears={totalYears}
                data={data.returnOnEquity}
                title="return on equity"
              />
            )}
          </div>
        )}
        {ratioTab === "profitability" && (
          <div
            className="ratio-profitability-charts hidden"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {data?.eps && (
              <RatioSplineChart
                totalYears={totalYears}
                data={data.eps}
                title="EPS"
              />
            )}
            {data?.netProfit && (
              <RatioSplineChart
                totalYears={totalYears}
                data={data.netProfit}
                title="net profit"
              />
            )}
            {data?.ebit && (
              <RatioSplineChart
                totalYears={totalYears}
                data={data.ebit}
                title="EBIT"
              />
            )}
            {data?.grossProfit && (
              <RatioSplineChart
                totalYears={totalYears}
                data={data.grossProfit}
                title="gross profit"
              />
            )}
            {data?.revenue && (
              <RatioSplineChart
                totalYears={totalYears}
                data={data.revenue}
                title="revenue"
              />
            )}
            {data?.ebitda && (
              <RatioSplineChart
                totalYears={totalYears}
                data={data.ebitda}
                title="ebitda"
              />
            )}
            {/* {data?.cashFlow && <RatioSplineChart  totalYears={totalYears} data={data.cashFlow} title="cash flow growth" />}*/}
          </div>
        )}
      </div>
    </section>
  );
};

export default RatioAnalysis;
