import React, { useEffect, useState } from "react";
import "./Portfolio.css";
import {
  useGetCompaniesListQuery,
  useAddNewPortfolioMutation,
  useGetPortfolioQuery,
  useGetUpcomingEarningMutation,
  useGetUpcomingSplitMutation,
  useGetUpcomingNewsMutation,
  useGetReportDataMutation,
} from "../api/api";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { PortfolioPolarChart } from "./PortfolioPolarChart";
import { SuggestionInput } from "../AutoSuggest/SuggestionInput";
import PieChart from "./PortfolioPieChart";
import PortfolioModel from "./PortfolioModel";

export const Portfolio = ({ selectedWindows }) => {
  const { data: companiesList } = useGetCompaniesListQuery();
  const { data: portfolioData } = useGetPortfolioQuery();
  const [addNewPortfolio, { isLoading: isPortfolioLoading }] =
    useAddNewPortfolioMutation();

  const [getUpcomingEarning, { data: UpcomingEarningData }] =
    useGetUpcomingEarningMutation();

  const [getUpcomingSplit, { data: UpcomingSplitsData }] =
    useGetUpcomingSplitMutation();

  const [getUpcomingNews, { data: UpcomingNewsData }] =
    useGetUpcomingNewsMutation();

  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [portfolioDate, setPortfolioDate] = useState(new Date());
  const [portfolioType, setPortfolioType] = useState("");
  const [portfolioQty, setPortfolioQty] = useState("");
  const [portfolioPrice, setPortfolioPrice] = useState("");
  const [portfolioCommission, setPortfolioCommission] = useState("");
  const [getReportData, { data: reportData, isLoading: isReportLoading }] =
    useGetReportDataMutation();

  // Modal states
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");

  const handleModalClose = () => {
    setOpen(false);
    setTitle("");
  };

  useEffect(() => {}, [UpcomingEarningData, UpcomingSplitsData]);

  const handleUpcomingearning = async () => {
    await getUpcomingEarning({
      symbols: portfolioData.portfolio
        .map((data) => `${data.symbol}.${data.exchange}`)
        .join(","),
    });
  };

  const handleUpcomingSplit = async () => {
    await getUpcomingSplit({
      symbols: portfolioData.portfolio.map(
        (data) => `${data.symbol}.${data.exchange}`
      ),
    });
  };

  const handleUpcomingNews = async (symbol) => {
    await getUpcomingNews({
      symbol,
    });
  };

  const handleReportData = async (symbol) => {
    await getReportData({
      symbol,
    });
  };

  const handleModalOpen = async (title, type, symbol) => {
    switch (type) {
      case "Upcoming Earnings":
        await handleUpcomingearning();
        break;
      case "Upcoming Splits":
        await handleUpcomingSplit();
        break;
      case "Upcoming News":
        await handleUpcomingNews(symbol);
        break;
      case "Annual Report":
      case "Quarterly Report":
        await handleReportData(symbol);
        break;

      default:
        break;
    }
    setOpen(true);
    setTitle(title);
  };
  const [portfolioInvestedAmount, setTotalINvestedAmount] = useState(null);
  const [marketValue, setMarketValue] = useState(null);
  const [portfolioProfit, setPortfolioProfit] = useState(null);
  const [portfolioProfitPercent, setPortfolioPercent] = useState(null);
  const [errMsg, setErrMsg] = useState("");
  const [cagr, setCagr] = useState(null);
  useEffect(() => {
    setTotalINvestedAmount(
      portfolioData?.portfolio.reduce(
        (total, portfolio) => total + portfolio.price * portfolio.quantity,
        0
      )
    );
    setMarketValue(
      portfolioData?.portfolio.reduce(
        (total, portfolio) =>
          total + portfolio.marketPrice * portfolio.quantity,
        0
      )
    );
    setPortfolioProfit(
      portfolioData?.portfolio.reduce(
        (total, portfolio) =>
          total +
          (portfolio.marketPrice - portfolio.price) * portfolio.quantity,
        0
      )
    );
  }, [portfolioData?.portfolio?.length]);
  useEffect(() => {
    if (marketValue && portfolioData?.portfolio?.length) {
      let firstInvestedValue =
        portfolioData.portfolio[0].price * portfolioData.portfolio[0].quantity;
      let totalYears = portfolioData.totalYears;
      const cagr = (marketValue / firstInvestedValue) ** (1 / totalYears) - 1;
      setCagr(cagr.toFixed(2));
    }
  }, [
    marketValue,
    portfolioData?.portfolio?.length,
    portfolioData?.totalYears,
  ]);
  useEffect(() => {
    setPortfolioPercent(
      ((portfolioProfit - portfolioInvestedAmount) / portfolioInvestedAmount) *
        100
    );
  }, [portfolioProfit, portfolioInvestedAmount]);
  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : companiesList.filter(
          (item) =>
            item.symbol.Name.toLowerCase().slice(0, inputLength) ===
              inputValue ||
            item.symbol.Code.toLowerCase().slice(0, inputLength) ===
              inputValue ||
            item.symbol.Exchange.toLowerCase().slice(0, inputLength) ===
              inputValue
        );
  };

  const getSuggestionValue = (suggestion) => suggestion.symbol.Name;

  const renderSuggestion = (suggestion) => <div>{suggestion.symbol.Name}</div>;

  const onSuggestionSelected = (event, { suggestion }) => {
    setSelectedValue(suggestion);
  };
  const handlePortfolioAdd = async () => {
    setErrMsg("");
    if (
      !portfolioType ||
      !portfolioQty ||
      !portfolioPrice ||
      !portfolioCommission ||
      !portfolioDate
    ) {
      setErrMsg("Please fill all required fields");
      return;
    }
    await addNewPortfolio({
      symbol: selectedValue.symbol.Code,
      exchange: selectedValue.symbol.Exchange,
      type: portfolioType.value,
      date: portfolioDate,
      quantity: portfolioQty,
      price: portfolioPrice,
      commission: portfolioCommission,
    });

    setSelectedValue("");
    setPortfolioType("");
    setPortfolioDate(new Date());
    setPortfolioQty("");
    setPortfolioPrice("");
    setPortfolioCommission("");
  };

  return (
    <div className="portfolio-wrapper">
      <div className="portfolio-container">
        {selectedWindows === "summary" && (
          <div className="portfolio-company-search">
            <div className="portfolio-company-input">
              <label>Add Portfolio</label>
              <SuggestionInput
                suggestions={suggestions}
                setSuggestions={setSuggestions}
                getSuggestions={getSuggestions}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={onSuggestionSelected}
                placeholder={"Company name, Symbol or Exchange"}
                value={value}
                setValue={setValue}
              ></SuggestionInput>
              {/* <Autosuggest
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={({ value }) => {
                                setSuggestions(getSuggestions(value));
                            }}
                            onSuggestionsClearRequested={() => {
                                setSuggestions([]);
                            }}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            onSuggestionSelected={onSuggestionSelected}
                            inputProps={{
                                placeholder: 'company name, symbol or exchange',
                                value,
                                onChange: (e, { newValue }) => {
                                    setValue(newValue);
                                },
                            }}
                        /> */}
            </div>

            {selectedValue && (
              <>
                <div className="portfolio-company-input">
                  <label>Type</label>
                  <Select
                    options={[
                      { value: "buy", label: "Buy" },
                      { value: "sell", label: "Sell" },
                    ]}
                    value={portfolioType}
                    onChange={(selectedType) => setPortfolioType(selectedType)}
                    placeholder="Select Type"
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        backgroundColor: "black",
                        color: "white",
                        borderColor: "black",
                        outline: "none",
                      }),
                    }}
                  />
                </div>
                <div className="portfolio-company-input">
                  <label>Date</label>
                  <DatePicker
                    selected={portfolioDate}
                    onChange={(date) => setPortfolioDate(date)}
                  />
                </div>
                <div className="portfolio-company-input">
                  <label>Quantity</label>
                  <input
                    type="text"
                    value={portfolioQty}
                    onChange={(e) => setPortfolioQty(e.target.value)}
                  />
                </div>
                <div className="portfolio-company-input">
                  <label>Price</label>
                  <input
                    type="text"
                    value={portfolioPrice}
                    onChange={(e) => setPortfolioPrice(e.target.value)}
                  />
                </div>
                <div className="portfolio-company-input">
                  <label>Commission</label>
                  <input
                    type="text"
                    value={portfolioCommission}
                    onChange={(e) => setPortfolioCommission(e.target.value)}
                  />
                </div>
                <div className="portfolio-company-button">
                  <button onClick={handlePortfolioAdd}>Add</button>
                </div>
              </>
            )}
          </div>
        )}
        <div
          style={{ color: "#ff0000", fontSize: "12px", paddingLeft: "13px" }}
        >
          {errMsg}
        </div>

        {portfolioData?.portfolio && portfolioData?.portfolio?.length > 0 && (
          <>
            {selectedWindows === "table" && (
              <div className="portfolio-list">
                <div className="profolio-table-summary">
                  <ul>
                    <li>
                      <h3>INVESTMENT</h3>
                      <div>{portfolioInvestedAmount}</div>
                    </li>
                    <li>
                      <h3>MARKET VALUE</h3>
                      <div>{marketValue?.toFixed(2)}</div>
                    </li>
                    <li>
                      <h3>PROFITS</h3>
                      <div
                        style={{ color: portfolioProfit > 0 ? "green" : "red" }}
                      >
                        {" "}
                        {portfolioProfit > 0
                          ? `+${portfolioProfit?.toFixed(2)}`
                          : portfolioProfit?.toFixed(2)}
                        <br></br>{" "}
                        {portfolioProfitPercent > 0
                          ? `+${portfolioProfitPercent?.toFixed(2)}%`
                          : `+${portfolioProfitPercent?.toFixed(2)}%`}
                      </div>
                    </li>
                    <li>
                      <h3>ROI | CAGR</h3>
                      <div
                        style={{
                          color: portfolioProfitPercent > 0 ? "green" : "red",
                        }}
                      >
                        {cagr}
                      </div>
                    </li>
                  </ul>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Symbol</th>
                      <th>Exchange</th>
                      {/* <th>Sector</th>
                                        <th>Industry</th> */}
                      <th>Type</th>
                      <th>Quantity</th>
                      <th>Buy Price</th>
                      <th>Buy Value</th>
                      <th>Market Price</th>
                      <th>Market Value</th>
                      <th>Profit</th>
                      <th>Profit %</th>
                      <th>Commission</th>
                    </tr>
                  </thead>
                  <tbody>
                    {portfolioData?.portfolio &&
                      portfolioData?.portfolio.map((portfolio, index) => (
                        <tr key={index}>
                          <td>
                            <div className="logoimage">
                              <img
                                src={portfolio.logo}
                                alt={portfolio.symbol}
                              />
                            </div>
                          </td>
                          <td>{portfolio.symbol}</td>
                          <td>{portfolio.exchange}</td>
                          {/* <td>{portfolio.sector}</td>
                                            <td>{portfolio.industry}</td> */}
                          <td>{portfolio.type}</td>
                          <td>{portfolio.quantity}</td>
                          <td>{portfolio.price}</td>
                          <td>{portfolio.price * portfolio.quantity}</td>
                          <td>{portfolio.marketPrice.toFixed(2)}</td>
                          <td>
                            {(
                              portfolio.marketPrice * portfolio.quantity
                            ).toFixed(2)}
                          </td>
                          <td>
                            {(
                              (portfolio.marketPrice - portfolio.price) *
                              portfolio.quantity
                            ).toFixed(2)}
                          </td>
                          <td>
                            {(
                              (((portfolio.marketPrice - portfolio.price) *
                                portfolio.quantity) /
                                (portfolio.price * portfolio.quantity)) *
                              100
                            ).toFixed(2)}
                            %
                          </td>
                          <td>{portfolio.commission}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}

            {selectedWindows === "summary" && (
              <div className="portfolio-graphs">
                <div className="portfolio-graph-item">
                  <PieChart
                    data={portfolioData.chartsData.company.map(
                      (value, index) => ({
                        name: portfolioData.chartsData.companyNames[index],
                        y: value,
                      })
                    )}
                    title="Market Allocation"
                  />
                </div>
                <div className="portfolio-graph-item">
                  <PieChart
                    data={portfolioData.chartsData.sector.map(
                      (value, index) => ({
                        name: value.name,
                        y: value.fund,
                      })
                    )}
                    title="Sector Allocation"
                  />
                </div>
                <div className="portfolio-graph-item">
                  <PieChart
                    data={portfolioData.chartsData.industry.map(
                      (value, index) => ({
                        name: value.name,
                        y: value.fund,
                      })
                    )}
                    title="Industry Allocation"
                  />
                </div>
                {/* <div className="portfolio-graph-item">
                  <h3>ROI | CAGR</h3>
                </div> */}
                {/* <div className="portfolio-graph-item">
                  <h3>ROI | CAGR</h3>
                </div> */}
              </div>
            )}
            {selectedWindows === "review" &&
              portfolioData?.portfolio &&
              portfolioData?.portfolio?.map((item) => (
                <div className="portfolio-review-list">
                  <ul>
                    {/* <h3>16 July 2023</h3> */}
                    <li>
                      <div>
                        <h2>{item.symbol}</h2>
                        <span>
                          {item.symbol} | {item.exchange}
                        </span>
                        <span>INR</span>
                        <span>
                          {item.sector} | {item.industry}{" "}
                        </span>
                      </div>
                      {/* MARK: Modals  */}
                      <div className="report-button-warpper">
                        <button
                          onClick={() =>
                            handleModalOpen(
                              `${item.symbol} |  Upcoming Earnings`,
                              "Upcoming Earnings",
                              item.symbol
                            )
                          }
                        >
                          Upcoming Earnings
                        </button>
                        <button
                          onClick={() =>
                            handleModalOpen(
                              `${item.symbol} | Upcomings Splits`,
                              "Upcoming Splits",
                              item.symbol
                            )
                          }
                        >
                          Upcoming Splits
                        </button>
                        <button
                          onClick={() =>
                            handleModalOpen(
                              `${item.symbol} | Upcomings News`,
                              "Upcoming News",
                              item.symbol
                            )
                          }
                        >
                          Upcoming News
                        </button>
                        <button
                          onClick={() =>
                            handleModalOpen(
                              `${item.symbol} | Annual Report`,
                              "Annual Report",
                              item.symbol
                            )
                          }
                        >
                          Annual Report
                        </button>
                        <button
                          onClick={() =>
                            handleModalOpen(
                              `${item.symbol} | Quarterly Report`,
                              "Quarterly Report",
                              item.symbol
                            )
                          }
                        >
                          Quarterly Report
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              ))}
          </>
        )}
      </div>
      {open && (
        <PortfolioModel
          open={open}
          title={title}
          handleClose={handleModalClose}
          UpcomingEarningData={UpcomingEarningData}
          UpcomingSplitsData={UpcomingSplitsData}
          UpcomingNewsData={UpcomingNewsData}
          ReportData={reportData}
        />
      )}
    </div>
  );
};
