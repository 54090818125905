import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import StatementAnalysisChart from "./StatementAnalysisChart";
import TotalYears from "./TotalYearsButtons/TotalYears";

const StatementAnalysis = ({
  data,
  selectedCompany,
  totalYears,
  setTotalYears,
  companyDetails,
}) => {
  const [adStatementTab, setAdStatementTab] = useState("income");

  return (
    <section style={{ position: "relative" }} id="pd-statement-analysis">
      {data === null && (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}
      <div className="qi-tooltip">
        <FontAwesomeIcon icon={faCircleInfo} />
        <div className="tooltiptext">
          Statement Analysis - Reviewing a company's financial statements
          provides a comprehensive view of its past business performance.
          Horizontal and vertical analyses of these statements offer detailed
          insights into how the company operated in various financial years.
          statements analysis advisor Examining a company's financial statements
          is a key to understanding its past business performance. By conducting
          both horizontal and vertical analyses, you can gain detailed insights
          into how the company has operated in various financial years, allowing
          for a meaningful comparison with its industry and sector.
        </div>
      </div>
      {/* <h2>Statement Analysis</h2> */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div className="horiz-buttons pd-sa-header-btns">
          <div>
            <button
              className={adStatementTab === "income" ? "active" : ""}
              onClick={() => setAdStatementTab("income")}
            >
              INCOME STATMENT
            </button>
          </div>
          <div>
            <button
              className={adStatementTab === "balance" ? "active" : ""}
              onClick={() => setAdStatementTab("balance")}
            >
              BALANCE SHEET
            </button>
          </div>
          <div>
            <button
              className={adStatementTab === "cash" ? "active" : ""}
              onClick={() => setAdStatementTab("cash")}
            >
              CASH FLOW
            </button>
          </div>
        </div>
        {/* <TotalYears totalYears={totalYears} setTotalYears={setTotalYears} /> */}
      </div>
      <div className="ratio-wrapper">
        <ul id="statement_logos"></ul>
        {adStatementTab === "income" && (
          <div className="sa-container" id="pd-sa-income-statement">
            <div className="sa-table-container">
              <ul className="sa-charts-list">
                {Object.entries(data).map(([key, value]) => (
                  <>
                    {selectedCompany === key && (
                      <StatementAnalysisChart
                        totalYears={totalYears}
                        data={value}
                        title="Sales"
                        dataKey="sales"
                        companyDetails={companyDetails}
                      />
                    )}

                    {selectedCompany === key && (
                      <StatementAnalysisChart
                        totalYears={totalYears}
                        data={value}
                        title="Gross Profit"
                        dataKey="gp"
                        companyDetails={companyDetails}
                      />
                    )}

                    {selectedCompany === key && (
                      <StatementAnalysisChart
                        totalYears={totalYears}
                        data={value}
                        title="operating income"
                        dataKey="oi"
                        companyDetails={companyDetails}
                      />
                    )}
                  </>
                ))}

                {Object.entries(data).map(([key, value]) => (
                  <>
                    {selectedCompany === key && (
                      <StatementAnalysisChart
                        totalYears={totalYears}
                        data={value}
                        title="pretax income"
                        dataKey="pretax"
                        companyDetails={companyDetails}
                      />
                    )}

                    {selectedCompany === key && (
                      <StatementAnalysisChart
                        totalYears={totalYears}
                        data={value}
                        title="net income"
                        dataKey="netI"
                        companyDetails={companyDetails}
                      />
                    )}

                    {selectedCompany === key && (
                      <StatementAnalysisChart
                        totalYears={totalYears}
                        data={value}
                        title="ebitda"
                        dataKey="ebitdaSa"
                        companyDetails={companyDetails}
                      />
                    )}
                  </>
                ))}
              </ul>
            </div>
          </div>
        )}
        {adStatementTab === "balance" && (
          <div className="sa-container" id="pd-sa-balance-sheet">
            <div className="sa-table-container">
              <ul className="sa-charts-list">
                {Object.entries(data).map(([key, value]) => (
                  <>
                    {selectedCompany === key && (
                      <StatementAnalysisChart
                        companyDetails={companyDetails}
                        totalYears={totalYears}
                        data={value}
                        title="Total Current Assets"
                        dataKey="saCurrAssets"
                      />
                    )}
                  </>
                ))}
                {Object.entries(data).map(([key, value]) => (
                  <>
                    {selectedCompany === key && (
                      <StatementAnalysisChart
                        companyDetails={companyDetails}
                        totalYears={totalYears}
                        data={value}
                        title="Total Assets"
                        dataKey="saTAssets"
                      />
                    )}
                  </>
                ))}

                {Object.entries(data).map(([key, value]) => (
                  <>
                    {selectedCompany === key && (
                      <StatementAnalysisChart
                        companyDetails={companyDetails}
                        totalYears={totalYears}
                        data={value}
                        title="Total Current liabilities"
                        dataKey="saCLiabilities"
                      />
                    )}
                  </>
                ))}

                {Object.entries(data).map(([key, value]) => (
                  <>
                    {selectedCompany === key && (
                      <StatementAnalysisChart
                        companyDetails={companyDetails}
                        totalYears={totalYears}
                        data={value}
                        title="Total Non Current liabilities"
                        dataKey="saNonCLiabilities"
                      />
                    )}
                  </>
                ))}
                {/* <li>
                                <div className="sa-chart-wrapper" id="pd-sa-liabilities"></div>
                                <h3>Total liabilities</h3>
                                <div className="sa-chart-wrapper" id="pd-sa-liabilities-per"></div>
                            </li> */}
                {Object.entries(data).map(([key, value]) => (
                  <>
                    {selectedCompany === key && (
                      <StatementAnalysisChart
                        totalYears={totalYears}
                        data={value}
                        title="Total liabilities"
                        dataKey="saTLiab"
                        companyDetails={companyDetails}
                      />
                    )}
                  </>
                ))}

                {Object.entries(data).map(([key, value]) => (
                  <>
                    {selectedCompany === key && (
                      <StatementAnalysisChart
                        totalYears={totalYears}
                        data={value}
                        title="Total shareholders equity"
                        dataKey="saEquity"
                        companyDetails={companyDetails}
                      />
                    )}
                  </>
                ))}
              </ul>
            </div>
          </div>
        )}
        {adStatementTab === "cash" && (
          <div className="sa-container" id="pd-sa-cash-flow">
            <div className="sa-table-container">
              <ul className="sa-charts-list">
                {Object.entries(data).map(([key, value]) => (
                  <>
                    {selectedCompany === key && (
                      <StatementAnalysisChart
                        totalYears={totalYears}
                        data={value}
                        title="Operating cash Flow"
                        dataKey="saOcf"
                        companyDetails={companyDetails}
                      />
                    )}
                  </>
                ))}

                {Object.entries(data).map(([key, value]) => (
                  <>
                    {selectedCompany === key && (
                      <StatementAnalysisChart
                        totalYears={totalYears}
                        data={value}
                        title="Investing cash Flow"
                        dataKey="saIcf"
                        companyDetails={companyDetails}
                      />
                    )}
                  </>
                ))}

                {Object.entries(data).map(([key, value]) => (
                  <>
                    {selectedCompany === key && (
                      <StatementAnalysisChart
                        totalYears={totalYears}
                        data={value}
                        title="financing cash Flow"
                        dataKey="saFcf"
                        companyDetails={companyDetails}
                      />
                    )}
                  </>
                ))}

                {Object.entries(data).map(([key, value]) => (
                  <>
                    {selectedCompany === key && (
                      <StatementAnalysisChart
                        totalYears={totalYears}
                        data={value}
                        title="Free cash Flow"
                        dataKey="saFreecf"
                        companyDetails={companyDetails}
                      />
                    )}
                  </>
                ))}

                {Object.entries(data).map(([key, value]) => (
                  <>
                    {selectedCompany === key && (
                      <StatementAnalysisChart
                        totalYears={totalYears}
                        data={value}
                        title="End Cash Position"
                        dataKey="saECp"
                        companyDetails={companyDetails}
                      />
                    )}
                  </>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default StatementAnalysis;
