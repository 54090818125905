import { useState, useEffect } from "react";
import {
  useGetAdvisorCompaniesQuery,
  useGetAdvisorChartDataMutation,
  useGetAdvisorCompaniesMutation,
  useGetYearlyDataMutation,
  useGetAdvisorChartPercentAgeDataMutation,
} from "../api/api";
import "./Advisor.css";

import PriceValue from "./PriceValue";
import RatioAnalysis from "./RatioAnalysis";
import StatementAnalysis from "./StatementAnalysis";
const workerScript = new Worker("/workers/priceWorker.js");

const Advisor = ({
  getLivePricePaid,
  selectedWindows,
  totalYears,
  setTotalYears,
  livePricePaid,
  activeYear,
  setActiveYear,
  companyList,
  companiesLivePriceData,
}) => {
  const [allCData, setAllCData] = useState({});
  const [selectedCompany, setSelectedCompany] = useState({});

  const [getAdvisorChartData, { isLoading: isChartDataLoading }] =
    useGetAdvisorChartDataMutation();
  const [companyDetails, setCompanyDetails] = useState({});
  const [getYearlyData, { data: yearlyData }] = useGetYearlyDataMutation();
  const [getAdvisorChartPercentAgeData, { data: advisorChartData }] =
    useGetAdvisorChartPercentAgeDataMutation();

  useEffect(() => {
    async function fetchData() {
      if (companyList?.length) {
        for (let i = 0; i < companyList.length; i++) {
          if (i === 0) {
            setSelectedCompany(companyList[i].Code);
            setCompanyDetails({
              name: companyList[i].Code,
              sector: companyList[i].Sector,
              industry: companyList[i].Industry,
            });
            getLivePricePaid({
              symbol: companyList[i].symbol,
              exchange: companyList[i].exchange_x,
            });
          }
          let companyData = await getAdvisorChartData({
            symbol: companyList[i].symbol,
            exchange: companyList[i].exchange_x,
            Sector: companyList[i].Sector,
            Industry: companyList[i].Industry,
          });
          setAllCData((prevState) => ({
            ...prevState,
            [companyList[i].Code]: companyData.data,
          }));
        }
      }
    }
    fetchData();
  }, [companyList, getAdvisorChartData, getLivePricePaid]);

  useEffect(() => {
    if (companyList?.length) {
      getAdvisorChartPercentAgeData({
        companiesList: companyList?.length
          ? companyList.map((c) => ({ symbol: c.symbol, exchange: "NSE" }))
          : [],
        year: activeYear,
      });
    }
  }, [activeYear, companyList]);

  return (
    <>
      <div className="paid-section">
        {selectedWindows !== "price_value" && (
          <div className="paid-companies">
            <ul id="paid-companies-logos">
              {companyList?.length &&
                companyList.map((company, index) => (
                  <li
                    className={`company_logo_list ${
                      selectedCompany === company?.Code ? "active" : ""
                    }`}
                    onClick={() => {
                      setSelectedCompany(company.symbol);
                      getLivePricePaid({
                        symbol: company.symbol,
                        exchange: company.exchange_x,
                      });
                      setCompanyDetails({
                        name: company?.Code,
                        sector: company.Sector,
                        industry: company.Industry,
                      });
                    }}
                  >
                    <div className="tooltip">
                      {company?.LogoURL && company.LogoURL !== "" ? (
                        <div className="company_logo">
                          <img src={company.LogoURL} alt="Logo" />
                        </div>
                      ) : (
                        <div className="company_initials">
                          {company?.Code.substring(0, 2)}
                        </div>
                      )}

                      <span className="tooltiptext">{company.Name}</span>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        )}
        <div
          className={`paid-charts-section paid-1-charts-window ${
            selectedWindows === "price_value" ? "advisor_historical" : ""
          }`}
        >
          {selectedWindows === "price_value" && (
            <PriceValue
              data={advisorChartData?.data ?? []}
              selectedCompany={selectedCompany}
              companyDetails={companyDetails}
              totalYears={totalYears}
              setTotalYears={setTotalYears}
              companyList={companyList}
              setSelectedCompany={setSelectedCompany}
              setCompanyDetails={setCompanyDetails}
              setActiveYear={setActiveYear}
              activeYear={activeYear}
              companiesLivePriceData={companiesLivePriceData}
            />
          )}
          {selectedWindows === "ratio" && (
            <RatioAnalysis
              data={allCData}
              selectedCompany={selectedCompany}
              companyDetails={companyDetails}
              totalYears={totalYears}
              setTotalYears={setTotalYears}
            />
          )}
          {selectedWindows === "statement" && (
            <StatementAnalysis
              data={allCData}
              selectedCompany={selectedCompany}
              companyDetails={companyDetails}
              totalYears={totalYears}
              setTotalYears={setTotalYears}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Advisor;
