// import Highcharts from "highcharts";

// import HighchartsReact from "highcharts-react-official";

// const RatioSplineChart = ({
//   data,
//   title,
//   dataKey,
//   companyDetails,
//   totalYears,
// }) => {
//   console.log(data);

//   console.log("total year:", "dawkdhba");
//   const chartOptions = {
//     chart: {
//       type: "spline",
//       backgroundColor: "#ffffff",
//       height: (window.innerHeight - (110 + 142)) / 2,
//       events: {
//         load() {
//           let chart = this;
//           console.log(chart);

//           const xAxis = chart.xAxis[0];
//           const maxXValue = xAxis.getExtremes().max;
//           if (chart.series[0]) {
//             var pointSector =
//               chart.series[0].data[chart.series[0].data.length - 1];
//             chart.addAnnotation({
//               labels: [
//                 {
//                   point: {
//                     x: maxXValue,
//                     y: pointSector.plotY,
//                   },
//                   text: companyDetails.sector,
//                 },
//               ],
//               draggable: "",
//               allowOverlap: true,
//             });
//           }
//           if (chart.series[1]) {
//             var pointIndustry =
//               chart.series[1].data[chart.series[1].data.length - 1];
//             chart.addAnnotation({
//               labels: [
//                 {
//                   point: {
//                     x: maxXValue,
//                     y: pointIndustry.plotY - 20,
//                   },
//                   text: companyDetails.industry,
//                 },
//               ],
//               draggable: "",
//               allowOverlap: true,
//             });
//           }

//           if (chart.series[2]) {
//             var pointCompany =
//               chart.series[2].data[chart.series[2].data.length - 1];
//             chart.addAnnotation({
//               labels: [
//                 {
//                   point: {
//                     x: maxXValue,
//                     y: pointCompany.plotY - 10,
//                   },
//                   text: companyDetails.name,
//                 },
//               ],
//               draggable: "",
//               allowOverlap: true,
//             });
//           }
//         },
//       },
//     },
//     title: {
//       text: "",
//     },
//     xAxis: {
//       visible: false,
//       // reversed: true
//     },
//     yAxis: {
//       visible: false,
//     },
//     legend: {
//       enabled: false,
//     },
//     tooltip: {
//       enabled: false,
//     },
//     plotOptions: {
//       spline: {
//         animation: {
//           duration: 5000,
//         },
//         lineWidth: 2,
//         states: {
//           hover: {
//             lineWidth: 3,
//           },
//         },
//         marker: {
//           radius: 0,
//           enabled: false,
//         },
//       },
//     },
//   };

//   return (
//     <div className="pd-ratio-s-graph">
//       <h3>{title}</h3>
//       <div>
//         {data && (
//           <HighchartsReact
//             highcharts={Highcharts}
//             options={{
//               ...chartOptions,
//               series: [
//                 {
//                   name: "Sector",
//                   color: "#ffa500",
//                   data: [
//                     ...data.ratioAnalysis[dataKey].sector.slice(0, totalYears),
//                   ],
//                 },
//                 {
//                   name: "Industry",
//                   color: "#ffff00",
//                   data: [
//                     ...data.ratioAnalysis[dataKey].industry.slice(
//                       0,
//                       totalYears
//                     ),
//                   ],
//                 },
//                 {
//                   name: "Company",
//                   color: "#8bc34a",
//                   data: [
//                     ...data.ratioAnalysis[dataKey].company.slice(0, totalYears),
//                   ],
//                 },
//               ],
//             }}
//           />
//         )}
//       </div>
//     </div>
//   );
// };

// export default RatioSplineChart;

import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const RatioAnalysis = ({
  data,
  dataKey,
  title,
  totalYears,
  companyDetails,
}) => {
  const generateChartOptions = (data, dataKey, totalYears) => {
    // Extract data for the specific field
    const companyData = data.ratioAnalysis[dataKey].company
      .slice(0, totalYears)
      .map((item) => item[1]);
    // const sectorData = data.ratioAnalysis[dataKey].sector
    //   .slice(0, totalYears)
    //   .map((item) => item[1]);
    // const industryData = data.ratioAnalysis[dataKey].industry
    //   .slice(0, totalYears)
    //   .map((item) => item[1]);

    // Extract years from company data (assuming years are the same across company, sector, and industry)
    const years = data.ratioAnalysis[dataKey].company
      .slice(0, totalYears)
      .map((item) => item[0]);

    return {
      chart: {
        type: "spline",
        height: "300px",
      },
      title: {
        text: `${title} Comparison`,
      },
      xAxis: {
        title: {
          text: "Year",
        },
        categories: years, // Use years as x-axis labels
      },
      yAxis: {
        title: {
          text: `${dataKey} Value`,
        },
      },
      tooltip: {
        shared: true,
        valueDecimals: 2,
      },
      series: [
        {
          name: `${companyDetails.name}`,
          data: companyData,
        },
        // {
        //   name: `${companyDetails.sector}`,
        //   data: sectorData,
        // },
        // {
        //   name: `${companyDetails.industry}`,
        //   data: industryData,
        // },
      ],
    };
  };

  return (
    <div>
      {/* {Object.keys(fields).map((field, index) => ( */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "50px",
          marginRight: "100px",
          marginBottom: "50px",
        }}
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={generateChartOptions(data, dataKey, totalYears)}
        />
      </div>
      {/* ))} */}
    </div>
  );
};

export default RatioAnalysis;
