import { createAsyncThunk, createSlice, } from "@reduxjs/toolkit";
import { api } from "../api/api";

export const startKeepAliveTimer = createAsyncThunk(
    "app/keepAliveTimer",
    async (args, { dispatch }) => {
        try {
            setTimeout(async () => {
                const result = await dispatch(api.endpoints.sessionKeepAlive.initiate());

                if (result.error) {
                    console.log("Error keeping session alive");
                } else {
                    dispatch(startKeepAliveTimer());
                }
            }, 900000);
        } catch {
            console.log("Error keeping session alive");
        }
    }
);

const initialState = {
    initState: "idle"
};

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setAppInitState: (state, action) => {
            state.initState = action.payload;
        }
    }
});

export const { setAppInitState } = appSlice.actions;
