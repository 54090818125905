import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { dGreen, dRed, lGreen, lRed } from "../../../constants/constants";
import {
  format2decimal,
  isGraphLow,
  isGraphLowNew,
} from "../../../constants/utils";

const HistoricalPrice = ({ data, totalYears, title }) => {
  const formattedData = data.map((item) => ({
    x: new Date(item.date).getTime(), // Convert to timestamp
    y: item.close,
  }));

  const recentData = formattedData.slice(-totalYears);
  return (
    <section className="container" id="historical_price">
      <div className="qi-tooltip">
        <FontAwesomeIcon icon={faCircleInfo} />
        <div className="tooltiptext">
          Historical Price - Examining a stock's 20-year historical price data
          can provide valuable insights into its long-term growth trajectory.
        </div>
      </div>
      <figure className="highcharts-figure">
        <div id="historical_chart">
          {data && (
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  zoomType: "x",
                  backgroundColor: "#ffffff",
                  height: window.innerHeight - 255,
                },
                title: {
                  text: title && title != "" ? title : "",
                  align: "left",
                  style: {
                    color: "#000000",
                  },
                },
                xAxis: {
                  visible: true,
                  type: "datetime",
                  minRange: 48 * 3600 * 1000 * 30 * 12,
                  maxZoom: 48 * 3600 * 1000 * 30 * 12,
                  lineColor: "#000000",
                  title: {
                    text: "",
                    style: {
                      color: "#000000",
                    },
                  },
                  labels: {
                    style: {
                      color: "#000000",
                    },
                    formatter: function () {
                      return Highcharts.dateFormat("%Y", this.value);
                    },
                  },
                },
                yAxis: {
                  visible: false,
                },
                legend: {
                  enabled: false,
                },
                plotOptions: {
                  area: {
                    animation: {
                      duration: 5000,
                    },
                    fillColor: {
                      linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1,
                      },
                      stops: [
                        [0, isGraphLowNew(recentData) ? lRed : lGreen],
                        [1, "rgba(67 140 120 1)"],
                      ],
                    },
                    marker: {
                      radius: 0,
                      enabled: false,
                    },
                    lineWidth: 2,
                    states: {
                      hover: {
                        lineWidth: 2,
                      },
                    },
                    threshold: null,
                    lineColor: isGraphLowNew(recentData) ? dRed : dGreen,
                  },
                },
                tooltip: {
                  formatter: function () {
                    return (
                      Highcharts.dateFormat("%Y-%m", this.x) +
                      "<br/>" +
                      format2decimal(this.y)
                    );
                  },
                },
                series: [
                  {
                    type: "area",
                    name: "",
                    data: formattedData,
                  },
                ],
              }}
            />
          )}
        </div>
      </figure>
    </section>
  );
};

export default HistoricalPrice;
