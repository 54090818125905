import React from 'react';

function TotalYears({ totalYears, setTotalYears }) {
    return (
        <ul className='total-years'>
            <li className={totalYears === 3 ? "active" : ""} onClick={() => setTotalYears(3)}>3Y</li>
            <li className={totalYears === 5 ? "active" : ""} onClick={() => setTotalYears(5)}>5Y</li>
            <li className={totalYears === 10 ? "active" : ""} onClick={() => setTotalYears(10)}>10Y</li>
        </ul>
    );
}

export default TotalYears;