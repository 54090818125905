// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.indicator_analysis-container {
  width: 100%;
  /* height: 65vh; */
  border: none;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0px 0px 10px #474747;
  /* Add box shadow */
  background: #474747;
}

.horiz-buttons {
  padding: 1rem 1rem 1rem 1.5rem;
  display: flex;
}

.horiz-buttons div {
  padding: 0 0.5rem;
}

.indicator-trend-charts, .volatality-indicator-charts, .momentum-indicator-charts,
.pd-indicator-trend-charts, .pd-volatality-indicator-charts, .pd-momentum-indicator-charts {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem 1.5rem;
}

div.indicator-s-graph {
  width: 48%;
  margin: 1rem 1%;
  box-shadow: 0px 0px 10px #00ff19;
  border-radius: 15px;
  overflow: hidden;
}
div.indicator-s-graph.red-shadow {
  box-shadow: 0px 0px 10px #ff0000;
}

.iChars {
  flex: 1 1;
}
.indicator-charts-block {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/app/feature/css/indicator_analysis.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,gCAAgC;EAChC,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;EAC9B,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;EAEE,aAAa;EACb,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,eAAe;EACf,gCAAgC;EAChC,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,gCAAgC;AAClC;;AAEA;EACE,SAAO;AACT;AACA;EACE,aAAa;AACf","sourcesContent":[".indicator_analysis-container {\r\n  width: 100%;\r\n  /* height: 65vh; */\r\n  border: none;\r\n  border-radius: 30px;\r\n  overflow: hidden;\r\n  box-shadow: 0px 0px 10px #474747;\r\n  /* Add box shadow */\r\n  background: #474747;\r\n}\r\n\r\n.horiz-buttons {\r\n  padding: 1rem 1rem 1rem 1.5rem;\r\n  display: flex;\r\n}\r\n\r\n.horiz-buttons div {\r\n  padding: 0 0.5rem;\r\n}\r\n\r\n.indicator-trend-charts, .volatality-indicator-charts, .momentum-indicator-charts,\r\n.pd-indicator-trend-charts, .pd-volatality-indicator-charts, .pd-momentum-indicator-charts {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  padding: 0 1rem 1.5rem;\r\n}\r\n\r\ndiv.indicator-s-graph {\r\n  width: 48%;\r\n  margin: 1rem 1%;\r\n  box-shadow: 0px 0px 10px #00ff19;\r\n  border-radius: 15px;\r\n  overflow: hidden;\r\n}\r\ndiv.indicator-s-graph.red-shadow {\r\n  box-shadow: 0px 0px 10px #ff0000;\r\n}\r\n\r\n.iChars {\r\n  flex: 1;\r\n}\r\n.indicator-charts-block {\r\n  display: flex;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
