import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faScaleUnbalancedFlip,
  faUpRightAndDownLeftFromCenter,
  faBullseye,
  faArrowUpRightDots,
} from "@fortawesome/free-solid-svg-icons";
import RatioSplineChart from "./RatioSplinechart";
import TotalYears from "./TotalYearsButtons/TotalYears";

const RatioAnalysis = ({
  data,
  selectedCompany,
  companyDetails,
  totalYears,
  setTotalYears,
}) => {
  const [adRatioTab, setAdRatioTab] = useState("valuataion");

  return (
    <section style={{ position: "relative" }} id="pd-ratio-analysis">
      {data === null && (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}
      <div className="qi-tooltip">
        <FontAwesomeIcon icon={faCircleInfo} />
        <div className="tooltiptext">
          Ratio Analysis - Examining four years' worth of calculated ratios can
          provide valuable insights into the performance of a company's four
          fundamental pillars, indicating whether they are experiencing growth
          or decline. ratios analysis advisor Analyzing four years of calculated
          ratios can offer valuable insights into how a company's four
          fundamental pillars compare to those of its industry and sector.
        </div>
      </div>
      {/* <h2>Ratio Analysis</h2> */}
      {/* Add the chart content here */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div className="horiz-buttons pd-ra-header-btns">
          <div>
            <button
              className={adRatioTab === "valuataion" ? "active" : ""}
              onClick={() => setAdRatioTab("valuataion")}
            >
              Valuations <FontAwesomeIcon icon={faScaleUnbalancedFlip} />
            </button>
          </div>
          <div>
            <button
              className={adRatioTab === "risk" ? "active" : ""}
              onClick={() => setAdRatioTab("risk")}
            >
              Risk <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
            </button>
          </div>
          <div>
            <button
              className={adRatioTab === "effeciency" ? "active" : ""}
              onClick={() => setAdRatioTab("effeciency")}
            >
              Effeciency <FontAwesomeIcon icon={faBullseye} />
            </button>
          </div>
          <div>
            <button
              className={adRatioTab === "profitability" ? "active" : ""}
              onClick={() => setAdRatioTab("profitability")}
            >
              Profitability <FontAwesomeIcon icon={faArrowUpRightDots} />
            </button>
          </div>
        </div>
        {/* <TotalYears totalYears={totalYears} setTotalYears={setTotalYears} /> */}
      </div>
      <div className="pd-ratio-charts-block">
        <ul id="ratio_logos"></ul>
        {adRatioTab === "valuataion" && (
          <div className="pd-ratio-valuations-charts">
            {Object.entries(data).map(([key, value]) => (
              <>
                {selectedCompany === key && (
                  <RatioSplineChart
                    totalYears={totalYears}
                    companyDetails={companyDetails}
                    title="BEN GRAHAM NUMBER"
                    data={value}
                    dataKey="benGraham"
                  />
                )}
              </>
            ))}
            {Object.entries(data).map(([key, value]) => (
              <>
                {selectedCompany === key && (
                  <RatioSplineChart
                    totalYears={totalYears}
                    companyDetails={companyDetails}
                    title="Enterprise Value (EV)"
                    data={value}
                    dataKey="enterpriseValue"
                  />
                )}
              </>
            ))}
            {Object.entries(data).map(([key, value]) => (
              <>
                {selectedCompany === key && (
                  <RatioSplineChart
                    totalYears={totalYears}
                    companyDetails={companyDetails}
                    title="P/E Ratio (Adjusted)"
                    data={value}
                    dataKey="peRatio"
                  />
                )}
              </>
            ))}
            {Object.entries(data).map(([key, value]) => (
              <>
                {selectedCompany === key && (
                  <RatioSplineChart
                    totalYears={totalYears}
                    companyDetails={companyDetails}
                    title="P/B Ratio"
                    data={value}
                    dataKey="pbRatio"
                  />
                )}
              </>
            ))}
            {Object.entries(data).map(([key, value]) => (
              <>
                {selectedCompany === key && (
                  <RatioSplineChart
                    totalYears={totalYears}
                    companyDetails={companyDetails}
                    title="price/ sales"
                    data={value}
                    dataKey="priceSales"
                  />
                )}
              </>
            ))}
            {Object.entries(data).map(([key, value]) => (
              <>
                {selectedCompany === key && (
                  <RatioSplineChart
                    totalYears={totalYears}
                    companyDetails={companyDetails}
                    title="book value per share"
                    data={value}
                    dataKey="bookValue"
                  />
                )}
              </>
            ))}
          </div>
        )}
        {adRatioTab === "risk" && (
          <div className="pd-ratio-risk-charts">
            {Object.entries(data).map(([key, value]) => {
              return (
                <>
                  {selectedCompany === key && (
                    <RatioSplineChart
                      totalYears={totalYears}
                      companyDetails={companyDetails}
                      title="current Ratio"
                      data={value}
                      dataKey="currentRatio"
                    />
                  )}
                </>
              );
            })}
            {Object.entries(data).map(([key, value]) => (
              <>
                {selectedCompany === key && (
                  <RatioSplineChart
                    totalYears={totalYears}
                    companyDetails={companyDetails}
                    title="Debt/Equity"
                    data={value}
                    dataKey="debtEquity"
                  />
                )}
              </>
            ))}

            {Object.entries(data).map(([key, value]) => (
              <>
                {selectedCompany === key && (
                  <RatioSplineChart
                    totalYears={totalYears}
                    companyDetails={companyDetails}
                    title="Equity/Assets"
                    data={value}
                    dataKey="equityAsset"
                  />
                )}
              </>
            ))}
          </div>
        )}
        {adRatioTab === "effeciency" && (
          <div className="pd-ratio-effeciency-charts">
            {Object.entries(data).map(([key, value]) => (
              <>
                {selectedCompany === key && (
                  <RatioSplineChart
                    totalYears={totalYears}
                    companyDetails={companyDetails}
                    title="Assest turnover"
                    data={value}
                    dataKey="assetTurnover"
                  />
                )}
              </>
            ))}
            {Object.entries(data).map(([key, value]) => (
              <>
                {selectedCompany === key && (
                  <RatioSplineChart
                    totalYears={totalYears}
                    companyDetails={companyDetails}
                    title="Inventory Turnover"
                    data={value}
                    dataKey="inventoryTurnover"
                  />
                )}
              </>
            ))}

            {Object.entries(data).map(([key, value]) => (
              <>
                {selectedCompany === key && (
                  <RatioSplineChart
                    totalYears={totalYears}
                    companyDetails={companyDetails}
                    title="working capital"
                    data={value}
                    dataKey="workingCapital"
                  />
                )}
              </>
            ))}

            {Object.entries(data).map(([key, value]) => (
              <>
                {selectedCompany === key && (
                  <RatioSplineChart
                    totalYears={totalYears}
                    companyDetails={companyDetails}
                    title="return on assests"
                    data={value}
                    dataKey="returnOnAssets"
                  />
                )}
              </>
            ))}

            {Object.entries(data).map(([key, value]) => (
              <>
                {selectedCompany === key && (
                  <RatioSplineChart
                    totalYears={totalYears}
                    companyDetails={companyDetails}
                    title="return on equity"
                    data={value}
                    dataKey="returnOnEquity"
                  />
                )}
              </>
            ))}
          </div>
        )}
        {adRatioTab === "profitability" && (
          <div className="pd-ratio-profitability-charts">
            {Object.entries(data).map(([key, value]) => (
              <>
                {selectedCompany === key && (
                  <RatioSplineChart
                    totalYears={totalYears}
                    companyDetails={companyDetails}
                    title="EPS"
                    data={value}
                    dataKey="eps"
                  />
                )}
              </>
            ))}

            {Object.entries(data).map(([key, value]) => (
              <>
                {selectedCompany === key && (
                  <RatioSplineChart
                    totalYears={totalYears}
                    companyDetails={companyDetails}
                    title="net profit"
                    data={value}
                    dataKey="netProfit"
                  />
                )}
              </>
            ))}

            {Object.entries(data).map(([key, value]) => (
              <>
                {selectedCompany === key && (
                  <RatioSplineChart
                    totalYears={totalYears}
                    companyDetails={companyDetails}
                    title="EBIT"
                    data={value}
                    dataKey="ebit"
                  />
                )}
              </>
            ))}

            {Object.entries(data).map(([key, value]) => (
              <>
                {selectedCompany === key && (
                  <RatioSplineChart
                    totalYears={totalYears}
                    companyDetails={companyDetails}
                    title="gross profit"
                    data={value}
                    dataKey="grossProfit"
                  />
                )}
              </>
            ))}

            {Object.entries(data).map(([key, value]) => (
              <>
                {selectedCompany === key && (
                  <RatioSplineChart
                    totalYears={totalYears}
                    companyDetails={companyDetails}
                    title="revenue"
                    data={value}
                    dataKey="revenue"
                  />
                )}
              </>
            ))}

            {Object.entries(data).map(([key, value]) => (
              <>
                {selectedCompany === key && (
                  <RatioSplineChart
                    totalYears={totalYears}
                    companyDetails={companyDetails}
                    title="EBITDA"
                    data={value}
                    dataKey="ebitda"
                  />
                )}
              </>
            ))}

            {Object.entries(data).map(([key, value]) => (
              <>
                {selectedCompany === key && (
                  <RatioSplineChart
                    totalYears={totalYears}
                    companyDetails={companyDetails}
                    title="cash flow growth"
                    data={value}
                    dataKey="cashFlow"
                  />
                )}
              </>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default RatioAnalysis;
