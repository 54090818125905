import "./../css/analysis_hirarcy.css";
import "./../css/chart_analysis.css";
import "./../css/global.css";
import "./../css/historical_price.css";
import "./../css/indicator_analysis.css";
import "./../css/live_price.css";
import "./../css/login.css";
import "./../css/ratio_analysis.css";
import "./../css/searchbar.css";
import "./../css/statement_analysis.css";
import "./../css/stats_analysis.css";
import "./../css/theories_models_analysis.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

import React, { useState } from "react";

export const LivePrice = ({ data, selectedCompany }) => {
  return (
    <>
      {data && (
        <div id="details-container">
          <div>
            <span className="live-price-span-first">
              <h2>{selectedCompany.symbol?.Name || "Infosys Ltd"}</h2>
              <div className="col2-center white-text">
                <p>{data?.symbol || "INFY"},</p>
                <p>{data?.exchange || "NSE"}</p>
              </div>
              <div className="col2-center white-text">
                <p>INR</p>
              </div>
              <div className="col2-center white-text">
                <p>Information Technology</p>
              </div>
            </span>
            <span className="live-price-span-first">
              <p className="highlight">Live Price</p>
              <p className="highlight">{data?.close || "1010.00"}</p>
              <p>
                {data?.change || "+120.00"} | {data?.change_p || "+80"}%{" "}
                <FontAwesomeIcon icon={faArrowUp} />
              </p>
              {/* <p>{data?.chDate || "July 23, 2024"}</p> */}
            </span>
            <span className="live-price-span-second">
              <div className="col2-center">
                <p>Open: {data?.open || "1000"}</p>
                <p>High: {data?.high || "1050"}</p>
              </div>
              <div className="col2-center">
                <p>Low: {data?.low || "950"}</p>
                <p>Close: {data?.close || "1010"}</p>
              </div>
              <div className="col2-center">
                <p>52 Week H: 2000</p>
                <p>52 Week L: 800</p>
              </div>
            </span>
            {/* <span className="live-price-span-first">
                    <h3 className="fixed-heading3">Fifty Two Weeks</h3>
                    <div className="col2-center">
                        <p>L: 1681.00</p>
                        <p>H: 1700.50</p>
                    </div>
                    <div className="col2-center">
                        <p>LC: 5.75</p>
                        <p>HC: -13.75</p>
                    </div>
                </span> */}
          </div>
        </div>
      )}
    </>
  );
};

export default LivePrice;
