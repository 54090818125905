export function isGraphLowNew(data) {
  // Ensure there is data, and both the first and last items have valid 'y' values
  if (data.length > 0) {
    return +data[0].y >= +data[data.length - 1].y;
  }
  return true;
}

export function isGraphLow(data) {
  if (data.length > 0 && data[0][1] && data[data.length - 1][1]) {
    return parseFloat(data[0][1]) > parseFloat(data[data.length - 1][1]);
  }
  return true;
}
export function format2decimal(value) {
  return (Math.round(value * 100) / 100).toFixed(2);
}
