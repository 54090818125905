import { useState } from "react";
import { useRegisterMutation } from "../api/api";

const Register = ({ setShouldRegisterDisplay, setLoginSuccessMsg }) => {
  const [register, { isLoading, isError }] = useRegisterMutation();
  const [rFirstName, setRFirstName] = useState("");
  const [rlastName, setRLastName] = useState("");
  const [rEmail, setREmail] = useState("");
  const [rPassword, setRPassword] = useState("");
  const [rConPassword, setRConPassword] = useState("");
  const [rPhone, setRPhone] = useState("");
  const [isFormError, setIsFormError] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const handleRegister = async () => {
    setIsFormError(false);
    setErrMsg("");
    if (
      !rEmail ||
      !rPassword ||
      !rFirstName ||
      !rlastName ||
      !rConPassword ||
      !rPassword ||
      !rPhone
    ) {
      setIsFormError(true);
      setErrMsg("All fields are mandatory. Please fill all required data.");
      return;
    }
    if (rPassword !== rConPassword) {
      setIsFormError(true);
      setErrMsg("Password and Confirm password does not match");
      return;
    }

    let data = await register({
      email: rEmail,
      password: rPassword,
      first_name: rFirstName,
      last_name: rlastName,
      phone_num: rPhone,
    });

    if (data.data.status) {
      setLoginSuccessMsg(
        "Congratulations, your account has been successfully created. Please Login!"
      );
      setShouldRegisterDisplay(false);
    } else {
      setIsFormError(true);
      setErrMsg(data.data.message);
      return;
    }
  };

  return (
    <div className="modal-main">
      <div className="register-modal form-modal">
        {isLoading && (
          <div className="loader-wrapper">
            <div className="loader"></div>
          </div>
        )}
        <div className="form-wrapper">
          <h2>Register</h2>
          {(isError || isFormError) && (
            <div className="register-err form-err">
              {errMsg || "There was an error submitting your request!"}
            </div>
          )}
          <form>
            <div className="form-group">
              <input
                type="text"
                className="inputText"
                placeholder=" "
                value={rFirstName}
                onChange={(e) => setRFirstName(e.target.value)}
              />
              <label for="register-first-name">First Name</label>
            </div>
            <div className="form-group">
              <input
                type="text"
                className="inputText"
                placeholder=" "
                value={rlastName}
                onChange={(e) => setRLastName(e.target.value)}
              />
              <label for="register-last-name">Last Name</label>
            </div>
            <div className="form-group">
              <input
                type="email"
                className="inputText"
                placeholder=" "
                value={rEmail}
                onChange={(e) => setREmail(e.target.value)}
              />
              <label for="register-email">email address</label>
            </div>
            <div className="form-group">
              <input
                type="password"
                placeholder=" "
                value={rPassword}
                onChange={(e) => setRPassword(e.target.value)}
              />
              <label for="register-password">password</label>
            </div>
            <div className="form-group">
              <input
                type="password"
                placeholder=" "
                value={rConPassword}
                onChange={(e) => setRConPassword(e.target.value)}
              />
              <label for="register-confirm-password">Confirm password</label>
            </div>
            <div className="form-group">
              <input
                type="tel"
                placeholder=" "
                value={rPhone}
                onChange={(e) => setRPhone(e.target.value)}
              />
              <label for="register-phone">Phone number</label>
            </div>
            <div className="form-group">
              <button
                type="button"
                className="form-button"
                onClick={handleRegister}
              >
                Submit
              </button>
              <div className="no-account">
                Already have an account?{" "}
                <b
                  onClick={() => {
                    setLoginSuccessMsg("");
                    setShouldRegisterDisplay(false);
                  }}
                >
                  Log in
                </b>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
