import Autosuggest from "react-autosuggest";
import "./SuggestionInput.css";

export const SuggestionInput = ({suggestions,setSuggestions,getSuggestions,getSuggestionValue,renderSuggestion,onSuggestionSelected,value,setValue})=>{
    return (
        <div className="suggestion-input">
            <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={({ value }) => {
                setSuggestions(getSuggestions(value));
            }}
            onSuggestionsClearRequested={() => {
                setSuggestions([]);
            }}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={onSuggestionSelected}
            inputProps={{
                placeholder: 'company name, symbol or exchange',
                value,
                onChange: (e, { newValue }) => {
                    setValue(newValue);
                },
            }}
        />
     </div>
    )
}