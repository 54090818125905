import { faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./../css/analysis_hirarcy.css";
import "./../css/chart_analysis.css";
import "./../css/forms.css";
import "./../css/global.css";
import "./../css/historical_price.css";
import "./../css/indicator_analysis.css";
import "./../css/live_price.css";
import "./../css/login.css";
import "./../css/ratio_analysis.css";
import "./../css/searchbar.css";
import "./../css/statement_analysis.css";
import "./../css/stats_analysis.css";
import "./../css/theories_models_analysis.css";

import React, { useEffect, useState } from "react";
import {
  useGetAdvisorCompaniesMutation,
  useGetCompaniesListQuery,
  useGetLivePriceMutation,
  useGetTrialMutation,
  useGetYearlyDataMutation,
} from "../api/api";

import Select from "react-select";
import Advisor from "../Advisor/Advisor";
import { SuggestionInput } from "../AutoSuggest/SuggestionInput";
import Login from "../Login/Login";
import Register from "../Login/Register";
import { Portfolio } from "../Portfolio/Portfolio";
import HistoricalPrice from "./HistoricalPrice";
import LivePrice from "./LivePrice";
import RatioAnalysis from "./RatioAnalysis";
import StatementAnalysis from "./StatementAnalysis";
import WindowNav from "./WindowNav";

export const Home = () => {
  const [
    getAdvisorCompanies,
    { data: companyList, isLoading: isCompanyListLoading },
  ] = useGetAdvisorCompaniesMutation();
  const [activeYear, setActiveYear] = useState(2024);
  const [activeTab, setActiveTab] = useState("trial");
  const [shouldRegisterDisplay, setShouldRegisterDisplay] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedWindows, setSelectedWindows] = useState("price_value");
  const [selectedTrialWindows, setSelectedTrialWindows] =
    useState("historical");
  const [selectedPortfolioWindows, setSelectedPortfolioWindows] =
    useState("summary");
  const [totalYears, setTotalYears] = useState(3);
  const [loginSuccessMsg, setLoginSuccessMsg] = useState("");
  const [showLogin, setShowLogin] = useState(false);

  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");
  const { data: companiesList } = useGetCompaniesListQuery();
  const [allCompaniesLivePriceData, setCompaniesLivePriceData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(
    companiesList?.length ? companiesList[0] : {}
  );

  const [getTrialData, { data, isLoading }] = useGetTrialMutation();
  const [getLivePrice, { data: livePrice, isloading: isLivePriceLoading }] =
    useGetLivePriceMutation();

  const [getYearlyData, { data: apiYearlyData, isLoading: yearlyDataLoader }] =
    useGetYearlyDataMutation();
  const [
    getLivePricePaid,
    { data: livePricePaid, isloading: isLivePricePaidLoading },
  ] = useGetLivePriceMutation();
  const [getCompanyLivePrice, { isloading: isCompanyLivePricePaidLoading }] =
    useGetLivePriceMutation();
  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("initials")) {
      getAdvisorCompanies({
        year: activeYear,
      });
    }
  }, [activeYear]);
  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("initials")) {
      const fetchLivePrices = async () => {
        if (companyList?.length) {
          let companiesLivePriceData = [];

          // Use Promise.all to wait for all async operations
          const promises = companyList.map(async (company) => {
            if (company?.logoData?.symbol && company?.logoData?.exchange) {
              let result = await getCompanyLivePrice({
                symbol: company?.logoData?.symbol,
                exchange: company?.logoData?.exchange,
              });
              return {
                marketPrice: result.data.close,
                change: result.data.change,
                change_p: result.data.change_p,
                symbol: result.data.symbol,
              };
            }
            return null;
          });

          // Await all the promises to be resolved
          companiesLivePriceData = await Promise.all(promises);

          // Filter out any null entries
          companiesLivePriceData = companiesLivePriceData.filter(Boolean);

          // Now you can set the state with the fully fetched data
          setCompaniesLivePriceData(companiesLivePriceData);
        }
      };

      fetchLivePrices();
    }
  }, [companyList]);
  useEffect(() => {
    getYearlyData({
      symbol: selectedValue?.symbol?.Code ?? "INFY",
      exchange: selectedValue?.symbol?.Exchange ?? "NSE",
      duration: totalYears,
    });
    getTrialData({
      symbol: selectedValue?.symbol?.Code ?? "INFY",
      exchange: selectedValue?.symbol?.Exchange ?? "NSE",
    });
    getLivePrice({
      symbol: selectedValue?.symbol?.Code ?? "INFY",
      exchange: selectedValue?.symbol?.Exchange ?? "NSE",
    });
  }, [getTrialData, getLivePrice, JSON.stringify(selectedValue), totalYears]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("initials");
    window.location.reload();
  };
  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : companiesList.filter(
          (item) =>
            item.symbol.Name.toLowerCase().slice(0, inputLength) ===
              inputValue ||
            item.symbol.Code.toLowerCase().slice(0, inputLength) ===
              inputValue ||
            item.symbol.Exchange.toLowerCase().slice(0, inputLength) ===
              inputValue
        );
  };

  const getSuggestionValue = (suggestion) => suggestion.symbol.Name;

  const renderSuggestion = (suggestion) => <div>{suggestion.symbol.Name}</div>;
  const onSuggestionSelected = (event, { suggestion }) => {
    setSelectedValue(suggestion);
  };
  return (
    <div
      className={`quant-main ${
        activeTab && activeTab !== "trial" ? "paid-quant-main" : ""
      }`}
    >
      <div className="quant-fixed">
        <header>
          <div
            className={`upper-nav ${
              activeTab && activeTab !== "trial" ? "paid-upper-nav" : ""
            }`}
          >
            <div className="head-main-container">
              <div className="head-main-left">
                <div className="toggle-switch tab-switch">
                  <div className="toggle-button">
                    <label
                      className="toggle-label trial-label"
                      onClick={() => setActiveTab("trial")}
                    >
                      <span
                        className={activeTab === "trial" ? "white-text" : ""}
                      >
                        Self
                      </span>
                    </label>
                    <label className={`switch ${activeTab}`}>
                      <span className="slider"></span>
                    </label>
                    <label
                      className="toggle-label paid-label"
                      onClick={() => setActiveTab("advisor")}
                    >
                      <span
                        className={activeTab === "advisor" ? "white-text" : ""}
                      >
                        Algorithm <FontAwesomeIcon icon={faWandMagicSparkles} />
                      </span>
                    </label>
                    <label
                      className="toggle-label portfolio-label"
                      onClick={() => setActiveTab("portfolio")}
                    >
                      <span
                        className={
                          activeTab === "portfolio" ? "white-text" : ""
                        }
                      >
                        Portfolio
                      </span>
                    </label>
                  </div>
                </div>
                <div className="search-wrapper">
                  {/* <div className="qi-logo">
                                    <img src={logo} alt="logo" />
                                </div> */}
                  {!(activeTab && activeTab !== "trial") && (
                    <SuggestionInput
                      suggestions={suggestions}
                      setSuggestions={setSuggestions}
                      getSuggestions={getSuggestions}
                      getSuggestionValue={getSuggestionValue}
                      renderSuggestion={renderSuggestion}
                      onSuggestionSelected={onSuggestionSelected}
                      placeholder={"Company name, Symbol or Exchange"}
                      value={value}
                      setValue={setValue}
                    />
                  )}
                </div>
              </div>

              {/* {activeTab && activeTab === "advisor" && <><div className="windows-select" style={{ width: 'fit-content' }}>
                                <Select
                                    options={[
                                        { value: 'price_value', label: 'Price Value' },
                                        { value: 'ratio', label: 'Ratio Analysis' },
                                        { value: 'statement', label: 'Statement Analysis' }
                                    ]}
                                    value={selectedWindows}
                                    onChange={(selectedWindows) => {
                                        console.log(selectedWindows);
                                        setSelectedWindows(selectedWindows);
                                    }}
                                    placeholder="Select Windows"
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: 'black',
                                            color: 'white',
                                            borderColor: 'black',
                                            outline: 'none'
                                        }),
                                    }}
                                />

                                <div className="section-header-filters">
                                    <Select
                                        options={[
                                            { value: '3', label: '3 Years' },
                                            { value: '5', label: '5 Years' },
                                            { value: '10', label: '10 Years' }
                                        ]}
                                        onChange={(totalYears) => setTotalYears(totalYears.value)}
                                        value={{ value: totalYears, label: `${totalYears} Years` }}
                                        placeholder="Select Windows"
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: 'black',
                                                color: 'white',
                                                borderColor: 'black',
                                                outline: 'none'
                                            }),
                                        }}
                                    />
                                </div>
                            </div>

                            </>} */}
              <div className="navbar">
                <div className="portfolio-company-input">
                  <Select
                    options={[
                      { value: "3", label: "3 Years" },
                      { value: "5", label: "5 Years" },
                      { value: "10", label: "10 Years" },
                    ]}
                    value={{
                      value: totalYears,
                      label: `${totalYears} Years`,
                    }}
                    onChange={(selectedType) => {
                      setTotalYears(+selectedType.value);
                    }}
                    placeholder="Time Frame"
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        backgroundColor: "black",
                        color: "white",
                        borderColor: "black",
                        outline: "none",
                      }),
                    }}
                  />
                </div>
                <div className="profile-menu">
                  {localStorage.getItem("initials") && (
                    <div
                      id="profile-circle"
                      onClick={() => setShowMenu(!showMenu)}
                    >
                      <span id="initials">
                        {localStorage.getItem("initials")}
                      </span>
                    </div>
                  )}
                  {localStorage.getItem("initials") && showMenu && (
                    <ul className="menu" id="menu">
                      <li>Manage Profile</li>
                      <li onClick={handleLogout}>Logout</li>
                    </ul>
                  )}
                </div>
                {localStorage.getItem("initials") ? (
                  <></>
                ) : (
                  // <div className="">
                  //         <button className="btn-rgt-space" onClick={handleLogout}>Profile</button>
                  //         <button onClick={handleLogout}>Logout</button>
                  //     </div>
                  <div className="">
                    <button onClick={() => setShowLogin(!showLogin)}>
                      Login
                    </button>
                  </div>
                )}
              </div>
            </div>
            {activeTab && activeTab === "trial" && (
              <WindowNav
                selectedWindows={selectedTrialWindows}
                setSelectedWindows={setSelectedTrialWindows}
                data={[
                  {
                    label: "Historical Data",
                    value: "historical",
                  },
                  {
                    label: "Ratio Data",
                    value: "ratio",
                  },
                  {
                    label: "Statements Data",
                    value: "statement",
                  },
                ]}
              />
            )}
            {activeTab && activeTab === "advisor" && (
              <WindowNav
                selectedWindows={selectedWindows}
                setSelectedWindows={setSelectedWindows}
                data={[
                  {
                    label: "Algorithm Recommandation",
                    value: "price_value",
                  },
                  {
                    label: "Recommendation Ratios",
                    value: "ratio",
                  },
                  {
                    label: "Recommendation Statements",
                    value: "statement",
                  },
                ]}
              />
            )}

            {activeTab && activeTab === "portfolio" && (
              <WindowNav
                selectedWindows={selectedPortfolioWindows}
                setSelectedWindows={setSelectedPortfolioWindows}
                data={[
                  {
                    label: "Portfolio summary",
                    value: "summary",
                  },
                  {
                    label: "Portfolio table",
                    value: "table",
                  },
                  {
                    label: "Portfolio Review",
                    value: "review",
                  },
                ]}
              />
            )}
          </div>
          <input type="hidden" id="comp-symbol" value="" />
          <input type="hidden" id="comp-exchange" value="" />
        </header>
        {activeTab && activeTab === "trial" && (
          <LivePrice
            selectedCompany={selectedValue}
            data={
              activeTab && activeTab !== "trial" ? livePricePaid : livePrice
            }
          />
        )}
      </div>

      <div className="main">
        {activeTab === "trial" && (
          <div className="trial-section">
            {selectedTrialWindows === "historical" && data?.historicalPrice && (
              <HistoricalPrice
                data={apiYearlyData?.data ?? []}
                totalYears={totalYears}
                title={selectedValue?.symbol?.Code ?? "INFY"}
              />
            )}

            {selectedTrialWindows === "ratio" && (
              <RatioAnalysis
                data={data?.ratioAnalysis || {}}
                totalYears={totalYears}
              />
            )}
            {selectedTrialWindows === "statement" && (
              <StatementAnalysis
                data={data?.statementAnalysis || {}}
                totalYears={totalYears}
              />
            )}
            {/* <section id="chartContainer" className="container"></section> */}
          </div>
        )}

        {activeTab === "advisor" &&
          localStorage.getItem("token") &&
          localStorage.getItem("initials") && (
            <Advisor
              activeYear={activeYear}
              setActiveYear={setActiveYear}
              getLivePricePaid={getLivePricePaid}
              livePricePaid={livePricePaid}
              selectedWindows={selectedWindows}
              totalYears={totalYears}
              setTotalYears={setTotalYears}
              companyList={companyList}
              companiesLivePriceData={allCompaniesLivePriceData}
            />
          )}

        {activeTab === "portfolio" &&
          localStorage.getItem("token") &&
          localStorage.getItem("initials") && (
            <Portfolio selectedWindows={selectedPortfolioWindows} />
          )}
      </div>

      {(showLogin ||
        (activeTab &&
          activeTab !== "trial" &&
          (!localStorage.getItem("token") ||
            !localStorage.getItem("initials")))) && (
        <>
          {!shouldRegisterDisplay && (
            <Login
              setShouldRegisterDisplay={setShouldRegisterDisplay}
              loginSuccessMsg={loginSuccessMsg}
            />
          )}
          {shouldRegisterDisplay && (
            <Register
              setShouldRegisterDisplay={setShouldRegisterDisplay}
              setLoginSuccessMsg={setLoginSuccessMsg}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Home;
