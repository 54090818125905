import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment"; // For easy date handling
import React from "react";

const CompanySplineChart = ({ data }) => {
  // Convert date strings to actual Date objects
  const dates = data[0].data_daily_price.map((item) =>
    moment(item.date, "YYYY-MM-DD").toDate()
  ); // Assuming all companies have the same date structure

  const series = data.map((company) => ({
    name: company.symbol,
    data: company.yearlyAverages.map((item) => ({
      x: moment(`${item.year}-12-31`, "YYYY-MM-DD").toDate(),
      y: item.cumulativeChange,
    })),
    type: "spline",
  }));

  const options = {
    title: {
      text: "Price Changes Comparison Between Companies",
    },
    xAxis: {
      type: "datetime",
      title: {
        text: "Year",
      },
      labels: {
        format: "{value:%Y}", // Display year only
      },
      tickInterval: 365 * 24 * 3600 * 1000, // One year interval in milliseconds
    },
    yAxis: {
      title: {
        text: "Change Value",
      },
      //   tickInterval: 100, // Adjust this value to increase the y-axis interval (set to 5 as an example)
    },
    series: series,
    tooltip: {
      shared: true,
      xDateFormat: "%Y-%m-%d", // Show full date on tooltip
      valueSuffix: " %",
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default CompanySplineChart;
