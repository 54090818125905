// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
div#react-autowhatever-1 {
    position: absolute;
    z-index: 999;
    background: #fff;
    overflow: hidden;
    border-radius: 30px;
}

.suggestion-input input {
    width: 250px;
    border: 1px solid #303030;
    /* background: black; */
    color: #000000;
    outline: none;
    font-size: 13px;
    padding: 8px 13px;
    border-radius: 30px;
}
.suggestion-input label {
    font-size: 12px;
    padding: 0 0 5px 8px;
}
.suggestion-input ul {
    border: 1px solid #303030;
    border-radius: 30px;
    list-style: none;
    padding: 0.25rem 1rem;
    margin: 5px 0 0 0;
}
.suggestion-input li {
    font-size: 13px;
    padding: 5px 0;
    border-bottom: 1px solid #303030;
    cursor: pointer;
}

.suggestion-input li:last-child {
    border-bottom: none;
}`, "",{"version":3,"sources":["webpack://./src/app/feature/AutoSuggest/SuggestionInput.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,uBAAuB;IACvB,cAAc;IACd,aAAa;IACb,eAAe;IACf,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,oBAAoB;AACxB;AACA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,qBAAqB;IACrB,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,cAAc;IACd,gCAAgC;IAChC,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["\ndiv#react-autowhatever-1 {\n    position: absolute;\n    z-index: 999;\n    background: #fff;\n    overflow: hidden;\n    border-radius: 30px;\n}\n\n.suggestion-input input {\n    width: 250px;\n    border: 1px solid #303030;\n    /* background: black; */\n    color: #000000;\n    outline: none;\n    font-size: 13px;\n    padding: 8px 13px;\n    border-radius: 30px;\n}\n.suggestion-input label {\n    font-size: 12px;\n    padding: 0 0 5px 8px;\n}\n.suggestion-input ul {\n    border: 1px solid #303030;\n    border-radius: 30px;\n    list-style: none;\n    padding: 0.25rem 1rem;\n    margin: 5px 0 0 0;\n}\n.suggestion-input li {\n    font-size: 13px;\n    padding: 5px 0;\n    border-bottom: 1px solid #303030;\n    cursor: pointer;\n}\n\n.suggestion-input li:last-child {\n    border-bottom: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
