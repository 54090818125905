import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
});

const authBaseQuery = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status !== 401) return result;

  //In case of 401 redirect to OKTA
  //window.location.href = `https://www-prod-cockpit-west.ext.hpe.com/oauth2/authorization/hpe-okta?redirectUri=${encodeURIComponent(window.location.href)}`;
  return result;
};

//Consider moving endpoints to own file if an entity has more than 2
export const api = createApi({
  reducerPath: "graph",
  baseQuery: authBaseQuery,
  tagTypes: ["trial"],
  endpoints: (builder) => ({
    getYearlyData: builder.mutation({
      query: (payload) => ({
        url: "/yearly-price",
        method: "POST",
        body: payload,
      }),
    }),
    getTrial: builder.mutation({
      query: (payload) => ({
        url: `/trial`,
        method: "POST",
        body: payload,
      }),
    }),

    getUpcomingEarning: builder.mutation({
      query: (payload) => ({
        url: "/company/earnings",
        method: "POST",
        body: payload,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }),
    }),

    getUpcomingSplit: builder.mutation({
      query: (payload) => ({
        url: "/splits",
        method: "POST",
        body: payload,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }),
    }),
    getUpcomingNews: builder.mutation({
      query: (payload) => ({
        url: "/company/news",
        method: "POST",
        body: payload,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }),
    }),

    getAdvisorChartPercentAgeData: builder.mutation({
      query: (payload) => ({
        url: "/multiple/monthlyPrice",
        method: "POST",
        body: payload,
      }),
    }),

    getReportData: builder.mutation({
      query: (payload) => ({
        url: "/company/report",
        method: "POST",
        body: payload,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }),
    }),

    login: builder.mutation({
      query: (payload) => ({
        url: "/login",
        method: "POST",
        body: payload,
      }),
    }),

    register: builder.mutation({
      query: (payload) => ({
        url: "/register",
        method: "POST",
        body: payload,
      }),
    }),

    getAdvisorCompanies: builder.mutation({
      query: (payload) => ({
        url: `/suggested/companies`,
        method: "POST",
        body: payload,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }),
    }),

    getAdvisorChartData: builder.mutation({
      query: (payload) => ({
        url: `/advisor/company`,
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: payload,
      }),
    }),

    getSearchData: builder.mutation({
      query: (payload) => ({
        url: `/symbolsearch`,
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: payload,
      }),
    }),

    getLivePrice: builder.mutation({
      query: (payload) => ({
        url: `/liveprice`,
        method: "POST",
        body: payload,
      }),
    }),

    getCompaniesList: builder.query({
      query: () => ({
        url: `/company/list`,
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }),
    }),

    addNewPortfolio: builder.mutation({
      query: (payload) => ({
        url: `/user/portfolio`,
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: payload,
      }),
      invalidatesTags: ["portfolio"],
    }),

    getPortfolio: builder.query({
      query: () => ({
        url: `/user/portfolio`,
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }),
      providesTags: ["portfolio"],
    }),
  }),
});

export const {
  useGetTrialMutation,
  useLoginMutation,
  useRegisterMutation,
  useGetAdvisorCompaniesMutation,
  useGetAdvisorChartDataMutation,
  useGetSearchDataMutation,
  useGetLivePriceMutation,
  useGetCompaniesListQuery,
  useAddNewPortfolioMutation,
  useGetPortfolioQuery,
  useGetYearlyDataMutation,
  useGetAdvisorChartPercentAgeDataMutation,
  useGetUpcomingEarningMutation,
  useGetUpcomingSplitMutation,
  useGetUpcomingNewsMutation,
  useGetReportDataMutation,
} = api;
