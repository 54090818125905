import { useState } from "react";
import { useLoginMutation } from "../api/api";
import loginImage from "../images/login.png";
import logo from "../images/logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faEye } from '@fortawesome/free-regular-svg-icons'


const Login = ({ setShouldRegisterDisplay, loginSuccessMsg }) => {
    const [login, { isLoading, isError }] = useLoginMutation();
    const [lEmail, setLEmail] = useState("");
    const [lPassword, setLPassword] = useState("");
    const [isFormError, setIsFormError] = useState(false);
    const [errMsg, setErrMsg] = useState("");

    const handleLogin = async () => {
        setIsFormError(false);
        setErrMsg("");
        if (!lEmail || !lPassword) {
            setIsFormError(true);
            setErrMsg("All fields are mandatory. Please fill all required data.");
            return;
        }

        let data = await login({
            username: lEmail,
            password: lPassword
        });
        if (data.data && data.data.token && data.data.initials) {
            localStorage.setItem('token', data.data.token);
            localStorage.setItem('initials', data.data.initials);
            localStorage.setItem('currSeq', data.data?.currSeq || 0);
            window.location.reload();
        }
        else {
            setIsFormError(true);
            setErrMsg(data.message);
            return;
        }

    };

    return (
        <div className="modal-main">
            <div className="login-modal form-modal">
                {isLoading && <div className="loader-wrapper">
                    <div className="loader"></div>
                </div>}
                <div className="form-wrapper">
                    <div className="login-image-container">
                        <img src={loginImage} alt="Login" />
                    </div>
                    <div className="form-container">
                        <img src={logo} alt="Logo" className="login-logo" />
                        <h2>Sign up with email</h2>
                        {(isError || isFormError) && <div className="login-err form-err">{errMsg || "The login credentials you entered are invalid"}</div>}
                        {loginSuccessMsg && <div className="login-success-msg form-success">{loginSuccessMsg}</div>}
                        <form>
                            <div className="form-group">
                                <label for="login-email">Email or username</label>
                                <div className="input-field">
                                    <div className="input-icon">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </div>
                                    <input type="email" placeholder=" " value={lEmail} onChange={(e) => setLEmail(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label for="login-password">Password</label>
                                <div className="input-field">
                                    <div className="input-icon">
                                        <FontAwesomeIcon icon={faEye} />
                                    </div>
                                    <input type="password" placeholder=" " value={lPassword} onChange={(e) => setLPassword(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="no-account forgot-password"><b onClick={() => setShouldRegisterDisplay(true)}>Forgot Password?</b></div>
                            </div>
                            <div className="form-group">
                                <button type="button" onClick={handleLogin} className="form-button" id="login-submit">Sign In</button>
                                <div className="no-account">Don't have an account? <b onClick={() => setShouldRegisterDisplay(true)}>Sign up</b></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;