import React, { useState } from "react";
import StatementAreaSplineChart from "./StatementAreaSplineChart";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const StatementAnalysis = ({ data, title, totalYears }) => {
    const [statementTab, setStatementTab] = useState("income");
    return (
        <section style={{ position: "relative" }} id="statement-analysis">
            <div className="qi-tooltip">
                <FontAwesomeIcon icon={faCircleInfo} />
                <div className="tooltiptext">Statement Analysis - Reviewing a company's financial statements provides a
                    comprehensive view of its past business performance. Horizontal and vertical analyses of these statements
                    offer detailed insights into how the company operated in various financial years. statements analysis
                    advisor Examining a company's financial statements is a key to understanding its past business
                    performance. By conducting both horizontal and vertical analyses, you can gain detailed insights into how
                    the company has operated in various financial years, allowing for a meaningful comparison with its
                    industry and sector.</div>
            </div>
            {/* <h2>Statement Analysis</h2> */}
            <div className="horiz-buttons sa-header-btns">
                <div><button className={statementTab === "income" ? "active" : ""} onClick={() => setStatementTab("income")}>INCOME STATMENT</button></div>
                <div><button className={statementTab === "balance" ? "active" : ""} onClick={() => setStatementTab("balance")}>BALANCE SHEET</button></div>
                <div><button className={statementTab === "cash" ? "active" : ""} onClick={() => setStatementTab("cash")}>CASH FLOW</button></div>
            </div>
            {statementTab === "income" && <div className="sa-container" id="sa-income-statement">
                <div className="sa-table-container">
                    <ul className="sa-charts-list">
                        <StatementAreaSplineChart totalYears={totalYears} data={data.sales} dataPer={data.salesPer} title="SALES" />
                        <StatementAreaSplineChart totalYears={totalYears} data={data.gp} dataPer={data.gpPer} title="GROSS PROFIT" />
                        <StatementAreaSplineChart totalYears={totalYears} data={data.oi} dataPer={data.oiPer} title="OPERATING INCOME" />
                        <StatementAreaSplineChart totalYears={totalYears} data={data.pretax} dataPer={data.pretaxPer} title="pretax income" />
                        <StatementAreaSplineChart totalYears={totalYears} data={data.netI} dataPer={data.netIPer} title="net income" />
                        <StatementAreaSplineChart totalYears={totalYears} data={data.ebitdaSa} dataPer={data.ebitdaSaPer} title="ebitda" />
                    </ul>
                </div>
            </div>}
            {statementTab === "balance" && <div className="sa-container hidden" id="sa-balance-sheet">
                <div className="sa-table-container">
                    <ul className="sa-charts-list">
                        <StatementAreaSplineChart totalYears={totalYears} data={data.saCurrAssets} dataPer={data.saCurrAssetsPer} title="TOTAL CURRENT ASSETS" />
                        {/* <StatementAreaSplineChart totalYears={totalYears} data={data.saNonCurrAssets} dataPer={data.saNonCurrAssetsPer} title="TOTAL NON CURRENT ASSETS" /> */}
                        <StatementAreaSplineChart totalYears={totalYears} data={data.saTAssets} dataPer={data.saTAssetsPer} title="TOTAL ASSETS" />
                        <StatementAreaSplineChart totalYears={totalYears} data={data.saCLiabilities} dataPer={data.saCLiabilitiesPer} title="TOTAL CURRENT LIABILITIES" />
                        <StatementAreaSplineChart totalYears={totalYears} data={data.saNonCLiabilities} dataPer={data.saNonCLiabilitiesPer} title="TOTAL NON CURRENT LIABILITIES" />
                        <StatementAreaSplineChart totalYears={totalYears} data={data.saTLiab} dataPer={data.saTLiabPer} title="TOTAL LIABILITIES" />
                        <StatementAreaSplineChart totalYears={totalYears} data={data.saEquity} dataPer={data.saEquityPer} title="TOTAL SHAREHOLDERS EQUITY" />
                    </ul>
                </div>
            </div>}
            {statementTab === "cash" && <div className="sa-container hidden" id="sa-cash-flow">
                <div className="sa-table-container">
                    <ul className="sa-charts-list">
                        <StatementAreaSplineChart totalYears={totalYears} data={data.saOcf} dataPer={data.saOcfPer} title="OPERATING CASH FLOW" />
                        <StatementAreaSplineChart totalYears={totalYears} data={data.saIcf} dataPer={data.saIcfPer} title="INVESTING CASH FLOW" />
                        <StatementAreaSplineChart totalYears={totalYears} data={data.saFcf} dataPer={data.saFcfPer} title="FINANCING CASH FLOW" />
                        <StatementAreaSplineChart totalYears={totalYears} data={data.saFreecf} dataPer={data.saFreecfPer} title="FREE CASH FLOW" />
                        <StatementAreaSplineChart totalYears={totalYears} data={data.saECp} dataPer={data.saECpPer} title="END CASH POSITION" />
                    </ul>
                </div>
            </div>}
        </section>
    );
};

export default StatementAnalysis;