// import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";
// import { isGraphLow, format2decimal } from "../../../constants/utils";
// import { lRed, dRed, lGreen, dGreen } from "../../../constants/constants";

import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const StatementAnalysisChart = ({
  data,
  dataKey,
  title,
  totalYears,
  companyDetails,
}) => {
  const generateChartOptions = (data, dataKey, totalYears) => {
    // Extract data for the specific field
    const companyData = data.statementAnalysis[dataKey].company
      .slice(0, totalYears)
      .map((item) => item[1]);
    // const sectorData = data.statementAnalysis[dataKey].sector
    //   .slice(0, totalYears)
    //   .map((item) => item[1]);
    // const industryData = data.statementAnalysis[dataKey].industry
    //   .slice(0, totalYears)
    //   .map((item) => item[1]);

    // Extract years from company data (assuming years are the same across company, sector, and industry)
    const years = data.statementAnalysis[dataKey].company
      .slice(0, totalYears)
      .map((item) => item[0]);

    return {
      chart: {
        type: "spline",
        height: "300px",
      },
      title: {
        text: `${title} Comparison`,
      },
      xAxis: {
        title: {
          text: "Year",
        },
        categories: years, // Use years as x-axis labels
      },
      yAxis: {
        title: {
          text: `${dataKey} Value`,
        },
      },
      tooltip: {
        shared: true,
        valueDecimals: 2,
      },
      series: [
        {
          name: `${companyDetails.name}`,
          data: companyData,
        },
        // {
        //   name: `${companyDetails.sector}`,
        //   data: sectorData,
        // },
        // {
        //   name: `${companyDetails.industry}`,
        //   data: industryData,
        // },
      ],
    };
  };

  return (
    <div>
      {/* {Object.keys(fields).map((field, index) => ( */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "50px",
          marginRight: "100px",
          marginBottom: "50px",
        }}
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={generateChartOptions(data, dataKey, totalYears)}
        />
      </div>
      {/* ))} */}
    </div>
  );
};

export default StatementAnalysisChart;

// const StatementAnalysisChart = ({ data, title, dataKey, totalYears }) => {
//     const chartOptions = {
//         chart: {
//             type: 'areaspline',
//             backgroundColor: '#ffffff',
//             height:  (window.innerHeight - (160 + 95)) / 2,
//         },
//         title: {
//             text: '',
//             align: 'left'
//         },
//         xAxis: {
//             visible: false
//         },
//         yAxis: {
//             visible: false
//         },
//         tooltip: {
//             enabled: false,
//             headerFormat: '<b>{point.x}</b><br>'
//         },
//         legend: {
//             enabled: false
//         },
//         marker: {
//             radius: 0,
//             enabled: false,
//             states: {
//                 hover: {
//                     enabled: false
//                 }
//             }
//         },
//         plotOptions: {
//             spline: {
//                 marker: {
//                     enabled: false
//                 }
//             },
//             area: {
//                 marker: {
//                     enabled: false
//                 }
//             }
//         },
//         credits: {
//             enabled: false
//         },
//         // plotOptions: saGreenPlotOptions
//     };

//     return (
//         <li>
//             <h3>{title}</h3>
//             <div className="sa-chart-div-container">
//                 <div className="sa-chart-wrapper">
//                     {data && <HighchartsReact
//                         highcharts={Highcharts}
//                         options={{
//                             ...chartOptions, series: [{
//                                 type: "spline",
//                                 name: "Sector",
//                                 color: "#ffa500",
//                                 data: [...data.statementAnalysis[dataKey].sector]
//                             }, {
//                                 type: "spline",
//                                 name: "Industry",
//                                 color: "#ffff00",
//                                 data: [...data.statementAnalysis[dataKey].industry]
//                             }, {
//                                 type: "area",
//                                 name: "Company",
//                                 color: "#8bc34a",
//                                 data: [...data.statementAnalysis[dataKey].company]
//                             }]
//                         }}
//                     />}
//                 </div>
//                 {/* <div className="sa-chart-wrapper">
//                     {data && <HighchartsReact
//                         highcharts={Highcharts}
//                         options={{
//                             ...chartOptions, series: [{
//                                 type: "spline",
//                                 name: "Sector",
//                                 color: "#ffa500",
//                                 data: [...data.statementAnalysis[dataKey + "Per"].sector]
//                             }, {
//                                 type: "spline",
//                                 name: "Industry",
//                                 color: "#ffff00",
//                                 data: [...data.statementAnalysis[dataKey + "Per"].industry]
//                             }, {
//                                 type: "area",
//                                 name: "Company",
//                                 color: "#8bc34a",
//                                 data: [...data.statementAnalysis[dataKey + "Per"].company]
//                             }]
//                         }}
//                     />}
//                 </div> */}
//             </div>
//         </li>

//     );
// };

// export default StatementAnalysisChart;
