import React, { useEffect } from "react";
import Home from "./app/feature/Home/Home";
// import { startKeepAliveTimer } from "./app/feature/app/appSlice";
// import { useAppDispatch } from "./app/hooks";

function App() {
  // const dispatch = useAppDispatch();

  // useEffect(() => {
  //   dispatch(startKeepAliveTimer());
  //   //Should be run only once
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  return (
    <Home/>
  );
}

export default App;
