import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsExporting from "highcharts/modules/exporting";
import React from "react";

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);

export const PortfolioPolarChart = ({ data }) => {
  const chartOptions = {
    chart: {
      polar: true,
      type: "column",
      backgroundColor: "#ffffff",
      height: window.innerHeight - (85 + 177),
    },
    title: {
      text: "",
    },
    pane: {
      size: "85%",
    },
    xAxis: {
      categories: data.companyNames,
      tickmarkPlacement: "on",
      lineWidth: 0,
      labels: {
        style: {
          color: "#000000", // Y-axis label color
        },
      },
    },
    yAxis: {
      min: 0,
      endOnTick: false,
      showLastLabel: true,
      title: {
        text: "Values",
      },
      labels: {
        style: {
          color: "#000000", // Y-axis label color
        },
        formatter: function () {
          return this.value;
        },
      },
      reversedStacks: false,
    },
    tooltip: {
      shared: true,
      pointFormat:
        '<span style="color:{series.color}">{series.name}: <b>{point.y}</b><br/>',
    },
    plotOptions: {
      column: {
        stacking: "normal",
        // pointPadding: 0,
        groupPadding: 0,
        shadow: false,
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      itemStyle: {
        color: "#000000",
      },
      itemHoverStyle: {
        color: "#000000",
      },
    },
    series: [
      {
        name: "Sector",
        data: data.sector,
        pointPlacement: "on",
      },
      {
        name: "Industry",
        data: data.industry,
        pointPlacement: "on",
      },
      {
        name: "Company",
        data: data.company,
        pointPlacement: "on",
      },
    ],
  };

  return (
    <div className="polar-wrapper">
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};
