// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';
// import { isGraphLow } from "../../../constants/utils";
// import { lRed, dRed, lGreen, dGreen } from "../../../constants/constants";

// const StatementAreaSplineChart = ({ data, dataPer, title, totalYears }) => {
//     const chartOptions = {
//         chart: {
//             type: 'areaspline',
//             backgroundColor: '#ffffff',
//             height: (window.innerHeight - (255 + 125)) / 2,
//         },
//         title: {
//             text: '',
//             align: 'left'
//         },
//         xAxis: {
//             visible: false
//         },
//         yAxis: {
//             visible: false
//         },
//         tooltip: {
//             enabled: false,
//             headerFormat: '<b>{point.x}</b><br>'
//         },
//         legend: {
//             enabled: false
//         },
//         marker: {
//             radius: 0,
//             enabled: false,
//             states: {
//                 hover: {
//                     enabled: false
//                 }
//             }
//         },
//         credits: {
//             enabled: false
//         },
//         // plotOptions: saGreenPlotOptions
//     };

//     const greenPlotOptions = {
//         areaspline: {
//             animation: {
//                 duration: 5000,
//             },
//             fillColor: {
//                 linearGradient: {
//                     x1: 0,
//                     y1: 0,
//                     x2: 0,
//                     y2: 1
//                 },
//                 stops: [
//                     [0, dGreen],
//                     [1, '#ffffff']
//                 ]
//             },
//             marker: {
//                 radius: 0,
//                 enabled: false,
//                 states: {
//                     hover: {
//                         enabled: false
//                     }
//                 }
//             },
//             lineWidth: 2,
//             states: {
//                 hover: {
//                     lineWidth: 2,
//                 }
//             },
//             threshold: null,
//             lineColor: lGreen,
//         }
//     };
//     const redPlotOptions = {
//         areaspline: {
//             animation: {
//                 duration: 5000,
//             },
//             fillColor: {
//                 linearGradient: {
//                     x1: 0,
//                     y1: 0,
//                     x2: 0,
//                     y2: 1
//                 },
//                 stops: [
//                     [0, dRed],
//                     [1, '#ffffff']
//                 ]
//             },
//             marker: {
//                 radius: 0,
//                 enabled: false,
//                 states: {
//                     hover: {
//                         enabled: false
//                     }
//                 }
//             },
//             lineWidth: 2,
//             states: {
//                 hover: {
//                     lineWidth: 2,
//                 }
//             },
//             threshold: null,
//             lineColor: lRed,
//         }
//     };

//     return (
//         <li className="statement-s-graph">
//             <h3>{title}</h3>
//             <div className="sa-chart-wrapper" id="sa-sales-chart">
//                 {data && <HighchartsReact
//                     highcharts={Highcharts}
//                     options={{
//                         ...chartOptions, series: [{
//                             name: '',
//                             data: [...data.slice(0, totalYears)]
//                         }],
//                         plotOptions: isGraphLow([...data.slice(0, totalYears)]) ? redPlotOptions : greenPlotOptions
//                     }}
//                 />}
//             </div>
//         </li>

//     );
// };

// export default StatementAreaSplineChart;

import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { isGraphLow, format2decimal } from "../../../constants/utils";
import { lRed, dRed, lGreen, dGreen } from "../../../constants/constants";

const StatementAreaSplineChart = ({
  data,

  title,
  totalYears,
}) => {
  const generateChartOptions = (data, totalYears) => {
    // Extract data for the specific field
    const companyData = data.slice(0, totalYears).map((item) => item[1]);

    // Extract years from company data (assuming years are the same across company, sector, and industry)
    const years = data.slice(0, totalYears).map((item) => item[0]);

    return {
      chart: {
        type: "spline",
        height: "300px",
      },
      title: {
        text: `${title} Comparison`,
      },
      xAxis: {
        title: {
          text: "Year",
        },
        categories: years, // Use years as x-axis labels
      },
      yAxis: {
        title: {
          text: `Value`,
        },
      },
      tooltip: {
        shared: true,
        valueDecimals: 2,
      },
      series: [
        {
          name: `Company`,
          data: companyData,
        },
      ],
    };
  };

  const redPlotOptions = {
    spline: {
      animation: {
        duration: 5000,
      },
      marker: {
        fillColor: dRed,
      },
      lineColor: dRed,
      lineWidth: 2,
      states: {
        hover: {
          lineWidth: 3,
        },
      },
    },
  };
  const greenPlotOptions = {
    spline: {
      animation: {
        duration: 5000,
      },
      marker: {
        fillColor: dGreen,
      },
      lineColor: dGreen,
      lineWidth: 2,
      states: {
        hover: {
          lineWidth: 3,
        },
      },
    },
  };

  return (
    <div>
      {/* {Object.keys(fields).map((field, index) => ( */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "50px",
          marginRight: "100px",
          marginBottom: "50px",
        }}
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            ...generateChartOptions(data, totalYears),

            plotOptions: isGraphLow(data) ? redPlotOptions : greenPlotOptions,
          }}
        />
      </div>
      {/* ))} */}
    </div>
  );
};

export default StatementAreaSplineChart;
