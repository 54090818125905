import React from 'react';

function WindowNav({selectedWindows, setSelectedWindows, data}) {
    return (
        <div className="window-nav">
            <ul>
                {data.map((item) => (
                    <li
                        key={item.label}
                        className={selectedWindows === item.value ? "active" : ""}
                        onClick={() => setSelectedWindows(item.value)}
                    >
                        {item.label}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default WindowNav;