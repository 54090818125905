import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import PriceValueChart from "./PriceValueChart";
import { useState } from "react";
import TotalYears from "./TotalYearsButtons/TotalYears";
import "./PriceValue.css";
import HistoricalPrice from "../Home/HistoricalPrice";
import CompanySplineChart from "./CompanySplineChart";

const PriceValue = ({
  data,
  selectedCompany,
  companyDetails,
  totalYears,
  setTotalYears,
  companyList,
  setSelectedCompany,
  setCompanyDetails,
  setActiveYear,
  activeYear,
  companiesLivePriceData,
}) => {
  const years = [
    2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013,
    2012, 2011, 2010,
  ];
  const formatNumber = (number) => {
    const num = parseFloat(number);

    if (isNaN(num)) {
      return number; // Return the original input if it's not a valid number
    }

    if (num >= 1e9) {
      return (num / 1e9).toFixed(1) + "B"; // Convert to billions and append 'B'
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + "M"; // Convert to millions and append 'M'
    }

    return num.toString(); // Return the original number as a string if it's less than a million
  };

  const handleYearClick = (year) => {
    setActiveYear(year); // Set the clicked year as active
  };
  return (
    <section className="advisor-price-value" id="price-value">
      {data === null && (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      )}
      <div className="qi-tooltip">
        <FontAwesomeIcon icon={faCircleInfo} />
        <div className="tooltiptext">
          Historical Price Advisor - Analyzing a 20-year historical price
          dataset can offer valuable insights into a company's performance
          relative to its industry and sector.
        </div>
      </div>
      {/* <h2>Price Value Analysis</h2> */}
      <div className="section-header-filters"></div>
      <figure className="highcharts-figure">
        {/* <TotalYears totalYears={totalYears} setTotalYears={setTotalYears} /> */}
        <div id="price_value_chart">
          {/* {Object.entries(data).map(([key, value]) => (
            <>
              {selectedCompany === key && ( */}
          {data && data.length && <CompanySplineChart data={data} />}
          {/* <HistoricalPrice
            data={data}
            totalYears={totalYears}
            title={selectedCompany?.symbol}
          /> */}
          {/* )}
            </> */}
          {/* ))} */}
        </div>
        <div
          className="price-value-companies-list"
          style={{ height: (window.innerHeight - 135) / 2 }}
        >
          <ul className="price-value-year-list">
            {years.map((year) => (
              <li
                key={year}
                className={activeYear === year ? "active" : ""}
                onClick={() => handleYearClick(year)}
              >
                {year}
              </li>
            ))}
          </ul>
          <ul className="price-value-companies">
            {companyList?.length &&
              companyList?.map((company, index) => (
                <li
                  className={`pv_company_list ${
                    selectedCompany === company?.Code ? "active" : ""
                  }`}
                  onClick={() => {
                    setSelectedCompany({
                      symbol: company?.Code,
                      exchange: "NSE",
                    });
                    setCompanyDetails({
                      name: company?.Code,
                      sector: company.Sector,
                      industry: company.Industry,
                    });
                  }}
                >
                  {company?.LogoURL && company.LogoURL !== "" ? (
                    <div className="company_logo">
                      <img src={company.LogoURL} alt="Logo" />
                    </div>
                  ) : (
                    <div className="company_initials">
                      {company?.Code?.substring(0, 2)}
                    </div>
                  )}
                  <div>{company.symbol}</div>
                  <div className="recommendation">
                    <p>{company.Sector}</p>
                    <p>{company.Industry}</p>{" "}
                  </div>
                  <div className="recommendation">
                    <p>Recommended Price:{company.close_merged}</p>{" "}
                    {/* <p>On Date{new Date(company.date_daily)}</p> */}
                  </div>
                  <div className="market-price">
                    {companiesLivePriceData[index]?.marketPrice
                      ? `Market price: ${companiesLivePriceData[index]?.marketPrice}`
                      : `Market price: Loading ...`}
                  </div>
                  <div
                    className="growth"
                    style={{
                      color: `${
                        companiesLivePriceData[index]?.marketPrice -
                          company.close_merged >
                        0
                          ? "green"
                          : "red"
                      }`,
                    }}
                  >
                    {}
                    {companiesLivePriceData[index]?.marketPrice &&
                      (
                        companiesLivePriceData[index]?.marketPrice -
                        company.close_merged
                      ).toFixed(2)}{" "}
                    {companiesLivePriceData[index]?.marketPrice &&
                      (
                        ((companiesLivePriceData[index]?.marketPrice -
                          company.close_merged) /
                          company.close_merged) *
                        100
                      ).toFixed(2) + "%"}
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </figure>
    </section>
  );
};

export default PriceValue;
