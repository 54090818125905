import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import AnnotationsFactory from "highcharts/modules/annotations";
import { format2decimal } from '../../../constants/utils';
AnnotationsFactory(Highcharts);


const PriceValueChart = ({ data, companyDetails, totalYears }) => {
    
    const chartOptions = {
        chart: {
            type: 'spline',
            zoomType: 'x',
            backgroundColor: '#ffffff',
            height: (window.innerHeight - 135) / 2,
            events: {
                load() {
                    let chart = this;
                    const xAxis = chart.xAxis[0];
                    const maxXValue = xAxis.getExtremes().max;
                    if (chart.series[0]) {
                        var pointSector = chart.series[0].data[chart.series[0].data.length - 1];
                        chart.addAnnotation({
                            labels: [{
                                point: {
                                    x: maxXValue,
                                    y: pointSector.plotY
                                },
                                text: companyDetails.sector
                            }],
                            draggable: '',
                            allowOverlap: true
                        });
                    }
                    if (chart.series[1]) {
                        var pointIndustry = chart.series[1].data[chart.series[1].data.length - 1];
                        chart.addAnnotation({
                            labels: [{
                                point: {
                                    x: maxXValue,
                                    y: pointIndustry.plotY - 20
                                },
                                text: companyDetails.industry
                            }],
                            draggable: '',
                            allowOverlap: true
                        });
                    }

                    if (chart.series[2]) {
                        var pointCompany = chart.series[2].data[chart.series[2].data.length - 1];
                        chart.addAnnotation({
                            labels: [{
                                point: {
                                    x: maxXValue,
                                    y: pointCompany.plotY - 10
                                },
                                text: companyDetails.name
                            }],
                            draggable: '',
                            allowOverlap: true
                        });

                    }
                }
            }
        },
        title: {
            text: '',
        },
        xAxis: {
            visible: true,
            type: 'datetime',
            // minRange: 48 * 3600 * 1000 * 20,
            // maxZoom: 48 * 3600 * 1000 * 20,
            // reversed: true,
            title: {
                text: 'Datetime',
                style: {
                    color: '#000000'
                },
            },
            labels: {
                visible: false,
                style: {
                    color: '#000000'
                },
                formatter: function () {
                    return this.value;
                }
            },
        },
        yAxis: {
            visible: false,
            title: {
                text: 'Price',
                style: {
                    color: '#000000'
                },
            },
            labels: {
                style: {
                    color: '#000000'
                }
            },
            gridLineColor: 'transparent',
            min: 0,
            minRange: 0
        },
        legend: {
            enabled: false,
            itemStyle: {
                color: '#000000'
            },
            itemHoverStyle: {
                color: '#000000'
            }
        },
        tooltip: {
            enabled: true,
            formatter: function () {
                return this.x + '<br/>' + format2decimal(this.y);
            }
        },
        plotOptions: {
            spline: {
                animation: {
                    duration: 5000,
                },
                lineWidth: 2,
                states: {
                    hover: {
                        lineWidth: 3,
                    }
                },
                marker: {
                    radius: 0,
                    enabled: false
                },
            },
            column: {
                animation: {
                    duration: 5000,
                },
                colorByPoint: true
            }
        }
    };

    return (
        <>
            {data?.historicalPrice?.sector && data?.historicalPrice?.industry && data?.historicalPrice?.company && <HighchartsReact
                highcharts={Highcharts}
                options={{
                    ...chartOptions, series: [{
                        name: "Sector",
                        color: "#ffa500",
                        data: [...data.historicalPrice.sector.slice(0, totalYears)],
                        dataSorting: {
                            enabled: false
                        },
                    }, {
                        name: "Industry",
                        color: "#ffff00",
                        data: [...data.historicalPrice.industry.slice(0, totalYears)],
                        dataSorting: {
                            enabled: false
                        },
                    }, {
                        name: "Company",
                        color: "#8bc34a",
                        data: [...data.historicalPrice.company.slice(0, totalYears)],
                        dataSorting: {
                            enabled: false
                        },
                    }]
                }}
            />}
        </>
    );
};

export default PriceValueChart;